import React from "react";
import {  db } from "../firebase";

import Card from "../Shared/CardLight";
import Footer from "../Shared/Footer";

import { Link } from "react-router-dom";

import ActivosP from "../assets/propetarios.png"
import AlertasP from "../assets/particulares.png"
import InformesP from "../assets/que-es.png"
import Banner from "../assets/banner.png"
import Money from "../assets/money.png"


import municipios from "../Shared/Municipios";
import CardParticular from "../Shared/CardParticular";


import CountUp from 'react-countup';


class Landing extends React.Component {



  constructor(props) {
    super(props) 
    this.state = {
      inmuebles: [],
      inmueblesparticulares: [],
      propiedad: "Todas",
      operacion: "Todas",
      zona: "Zona",
      money: 0,
      
      
    }



  }

 updateClock() {

    var startStamp = new Date(2021,1,14,23,59,59,0);
    
    var newDate = new Date();
    var newStamp = newDate.getTime();

    
    newDate = new Date();
    newStamp = newDate.getTime();
    var diff = Math.round((newStamp-startStamp)/1000);
    
    var d = Math.floor(diff/(24*60*60)); /* though I hope she won't be working for consecutive days :) */
    diff = diff-(d*24*60*60 );
    var h = Math.floor(diff/(60*60));
    diff = diff-(h*60*60);
    var m = Math.floor(diff/(6000));
    diff = diff-(m*60)  + 0.2;
    var s = diff / 10;
    
    this.setState({money: diff / 10 + 6200 + d  + s })
}
 
  
  componentDidMount() {
    this.getInmuebles()
    this.getInmueblesDestacado()
    setInterval(() => {
      this.updateClock();
    }, 1000);
  }

  


  addInmueble(newItem) {
    this.setState({ inmuebles: [...this.state.inmuebles, newItem] })
  }

  addInmuebleParticular(newItem) {
    this.setState({ inmueblesparticulares: [...this.state.inmueblesparticulares, newItem] })
  }
  handleChangeZonas = (event) => {
    this.setState({zona: event.target.value});
  }


  renderZonas() {
    var array = []
    var i;
    var data = municipios;
    for(i=0;i < data.length ;i++) {
      array.push(
        <option value={data[i].barrio_nombre}>{data[i].barrio_nombre}</option>
      )
    }
    return array;
  }

  async getInmueblesDestacado() {
    await db.collection('activos').where("destacado", "==", true).limit(6).get()
    .then(res => {
        res.forEach(doc => {
            let newItem = doc.data();
            newItem.id = doc.id;
            this.addInmuebleParticular(newItem)

        });
    })
    .catch(err => { console.error(err) });
  }


  async getInmuebles() {
      this.setState({inmuebles:[]})
      await db.collection('inmuebles').where("verificado", "==", true).limit(500).get()
      .then(res => {
          res.forEach(doc => {
              let newItem = doc.data();
              newItem.id = doc.id;
              this.addInmueble(newItem)

          });
      })
      .catch(err => { console.error(err) });
  } 

  renderInmuebles()  {
    var array = []
    var i ;
    var data = this.state.inmueblesparticulares;

    for(i=0; i < data.length; i++) {
       array.push(
         <div className="col-12 col-md-4 col-lg-4 mt-1">
            {this.renderCard(data[i])}
         </div>
      )

    }
    return array;
  }


  renderInmueblesParticulares()  {
    var array = []
    var i ;
    var data = this.state.inmueblesparticulares;
    data = data.sort(function(a, b) {
        return parseFloat(b.fecha) - parseFloat(a.fecha);
    });
  
    for(i=0; i < data.length; i++) {
       array.push(
         <div className="col-12 col-md-4 col-lg-4 mt-1">
            {this.renderCard(data[i])}
         </div>
      )

    }
    return array;
  }

  renderCard(item) {
    if(item.particular === true) {
      return  <CardParticular item={item}/>
    } else {
      return  <Card item={item}></Card>
    }
  }


  renderInmueblesHoy() {
      var data = this.state.inmuebles;
      data = data.sort(function(a, b) {
          return parseFloat(b.fecha) - parseFloat(a.fecha);
      });
      var filtro = "Retail";
    
      if(this.state.propiedad !== "Vivienda") {
        filtro = this.state.propiedad
      }


      data.filter((item) => item.propiedad === this.state.propiedad);
      data = data.slice(0,6)

      
      return(
          <>
          {data.map(item => (
               <div className="col-12 col-md-4 mt-1 mb-3 col-sm-12 col-lg-4">
               <Card item={item}></Card>
            </div>
  
          ))}

          </>
        )

    
  }


  render() {

      return (
        <div>
        <main className="">
        <div className=" mt-0   text-dark">
        <section className=" background">
          <div className="row pt-0 pt-md-0 pt-lg-0">
            <div className="col-xl-12 col-lg-12 col-md-12 order-md-1 pt-xl-0 pe-lg-0 mb-0 text-md-start noMobilPadTop">
              <div className="row  text-dark mt-5">
               

                <h1 className=" text-dark mt-5 italic text-center"><b>Bienvenido a <span className="text-primary">FotoHunters</span></b> 
                </h1>
                <h2 className="text-dark text-center">
                    Accede a los carteles con la oferta inmobiliaria de tu ciudad
                </h2>
              <div className="row mt-3">
                <div/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br/>
        <section className="container pb-4 pt-1 mb-5 mt-5">
          <div className="text-center  mb-4 pb-md-2">
              <h2 className="h2 mb-0 me-md-4 text-center">Accede al <span className="highlighFh">Huntersmaps</span></h2>
        
          </div>
     
          <section className="container mb-5 pb-md-2">
        
        <div className="row align-items-center">
          
        <div className="col-xxl-6 col-md-7 text-md-start text-center">
            <h2>¿Que es el <b className="text-primary">Huntersmaps</b>?</h2>
            <p className="pb-3 fs-lg text-dark">
              HunterMaps es la nueva herramienta de fotohunters que te da acceso a la oferta real que están en las calles de tu ciudad.
              <br/>
              <br/>
              Proporcionandote una base de datos de activos exclusivos y herramientas para profesionales del sector.

</p>

          <a className="btn btn-lg btn-primary" href="/huntermaps" target={"__blank"}>
            
          <i className="fi-arrow-long-right me-2" />Acceder a <b>Huntersmaps</b></a>


          </div>
          <div className="col-md-5 mt-2">
            <Link to={"/huntermaps"} onClick={() => window.scrollTo(0,0)}>
            <img  className="rounded  d-block mx-md-0 mx-auto mb-md-2 mb-4  border round-2 text-md-start text-right" src="./assets/foto.gif" width={"auto"} height={599} alt="Illustration" />
            </Link>
            </div>
        
        </div>
        
      </section>
        </section>

      

        <section>

        {/*<div className="container text-center text-dark ">
          <h1 className=" ">Fotohunters PRO</h1>
          <p className="contain">Fotohunters PRO te ofrece herramientas de expansión para individuos y agencias. Accede a todas las funcionalidades de Fotohunters sin límite</p>


          <div className="row  mt-5">
            <div className="row d-flex  justify-content-center mb-5">

              <div className="col-12 col-md-4">
                <img src={ActivosP} width={"160px"}  className="icon  img-fluid"></img>
                <h5>Activos</h5>
                <p>Accede a toda la oferta de Fotohunters. <br/> Locales, viviendas, transpasos... </p>
                <br/>
              </div>


              <div className="col-12 col-md-4">
                <img src={AlertasP} width={"160px"}  className="icon  img-fluid"></img>
                <h5>Alertas</h5>
                <p>Configura alertas personalizadas para tener la información antes que nadie.</p>
                <br/>
              </div>



              <div className="col-12 col-md-4">
                <img src={InformesP} width={"160px"}  className="icon  img-fluid"></img>
                <h5>Informes de expansion</h5>
                <p>¿Quieres abrir un nuevo negocio? Con Fotohunter PRO crea informes de expansión a tu medida.</p>
                <br/>
              </div>

            </div>

          </div>
        </div>
*/}


        <div>
        <div className="container text-center text-dark ">
          <h1 className=" ">Planes Fotohunters</h1>
          <p className="contain">Fotohunters cuenta con planes para usuarios particulares como para agencias y empresas.</p>
        </div>
        <div className="container  text-dark ">
          <div className="card-deck mb-3 text-center d-flex justify-content-center row">

          <div className="card m-2 box-shadow col-12 col-md-4">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Plan gratuito</h4>
              </div>
              <div className="card-body">
              <br/>
                <h1 className="card-title pricing-card-title">0€ <small className="text-muted">/mes</small></h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Accede a activos vivienda</li>
                  <li>Acceso a activos inmobiliarias</li>
                  <li>Acceso cuenta única</li>


                  <br/>
                  <br/>
                  <br/>
                  <br/>


                </ul>
              <Link to="/cuenta">   <button type="button" className="btn btn-lg btn-block btn-primary">Accede a la plataforma</button> </Link>
              </div>
            </div>

            <div className="card m-2 box-shadow col-12 col-md-4">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Plan PRO</h4>
              </div>
              <div className="card-body">
              <br/>
                <h1 className="card-title pricing-card-title">49.99 <small className="text-muted">/ mes</small></h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Fotohunters RETAIL</li>
                  <li>Alertas personalizadas</li>
                  <li>Herramientas expansión</li>
                  <li>Acceso multicuentas</li>

                  <br/>
                  <br/>
                  <br/>

                </ul>
              <Link to="/cuenta">   <button type="button" className="btn btn-lg btn-block btn-primary">Accede a la plataforma</button> </Link>
              </div>
            </div>
         
          
          </div>
        </div>
        
        </div>
     
    
          
        </section>
        <br/>    
        <br/>

        {/* Services*/}
        <hr className="mt-n1 mb-5 d-md-none" />
<div className="container text-center text-dark ">
          <h1 className=" ">Gana dinero como Hunter</h1>
          <p className="contain">Fotohunters te proporciona las herramientas para ganar dinero haciendo fotos  </p>


          <div className="row  mt-5">
            <div className="row d-flex  justify-content-center mb-5">

              <div className="col-12 col-md-4">
                <img src={Banner} width={"240px"}  className="  img-fluid"></img>
                <h5>Haz foto a carteles</h5>
                <p>Ve por tu ciudad haciendo fotos a carteles</p>
                <br/>
              </div>


              <div className="col-12 col-md-4">
                <img src={AlertasP} width={"160px"}  className="icon  img-fluid"></img>
                <h5>Verificamos tus activos</h5>
                <p>Una vez que subes las fotos a <b>Fotohunters</b> pasa por el proceso de verificación</p>
                <br/>
              </div>



              <div className="col-12 col-md-4">
                <img src={Money} width={"160px"}  className="icon  img-fluid"></img>
                <h5>Gana dinero</h5>
                <p>Una vez verificado, puedes ganar <b>2.5€-300€</b> por foto dependiendo de las descargas y compras del activos</p>
                <br/>
              </div>

            </div>

          </div>
        </div>




   
        {/* Property cost calculator*/}
        <section className="container mb-5 pb-2 pb-lg-4">
          <div className="row align-items-center">
            <div className="col-md-5"><img className="d-block mx-md-0 mx-auto mb-md-2 mb-4  text-md-start text-right" src="./assets/wanted.jpg" width={346} alt="Illustration" /></div>
            <div className="col-xxl-6 col-md-7 text-md-start text-center">
              <h2>Únete al equipo de hunters</h2>
              <p className="pb-3 fs-lg text-dark">!SOLICITA TU INGRESO COMO CAPTADOR Y CONSIGUE NUEVOS INGRESOS DE FORMA SENCILLA!</p><a className="btn btn-lg btn-primary" href="https://sasgm0pj550.typeform.com/to/BlQiMB1i?typeform-source=www.fotohunters.com" target={"__blank"}>
                <i className="fi-arrow-long-right me-2" />Registrate como Hunter</a>
            </div>
          </div>
        </section>
      

       

           <div className="text-center text-dark dotback m-5 border-rounded ">
          <br/>
          <h3>¿Quieres un plan personalizado para tu empresa?</h3>
          <p>Contáctanos para hablar más en detalle</p>
          <button type="button" className="btn btn-lg btn-block btn-primary">
          <a target={"__blank"} className="link text-white" href="https://wa.me/34691800608">Contáctanos</a>  
          </button>
          <br/>
          <br/>

        </div>
        
        <img src=""></img>

        <Footer></Footer>
      </div>
      </main>

        </div>
      );

    }
  }


export default Landing;
