import React from "react";

import Footer from "../Shared/Footer";

class Explicacion extends React.Component {


  constructor(props) {
    super(props) 
    this.state = {
      inmuebles: [],
      inmueblesparticulares: [],
      propiedad: "Vivienda",
      operacion: "Todas",
      zona: "Zona",
      money: 0,
 
      
    }
  }

  componentDidMount() { }

  render() {

      return (
        <div>
        <main className="page-wrapper">
        <div className="bg-light mt-5">
        <section className="container pt-0 my-2 pb-lg-4 mt-5 ">
        
        </section>
        <section className="container mb-5 pb-2 pb-lg-4 mt-5">
          <div className="row align-items-center">
          <h2>¿Que es <b>FotoHunters</b>?</h2>

            <div className="col-md-5"><img className="d-block mx-md-0 mx-auto mb-md-2 mb-4  text-md-start text-right rounded" src="./assets/que-es.png" width={446} alt="Illustration" /></div>
            <div className="col-xxl-6 col-md-7 text-md-start text-center">
              <p className="pb-3 fs-lg">Bienvenido a <b>FotoHunters</b> un nuevo buscador WEB de activos inmobiliarios que tiene como primer objetivo ofrecer calidad y veracidad en sus publicaciones.</p>
              <ul>
                <li>No se repiten los activos, un activo, un representante.</li>
                <li>Solo podrán ser  publicados activos por propietarios y exclusivistas.</li>

              </ul>
            <br>
            </br>
            
            <a className="btn btn-lg btn-primary" href="/register" >
                <i className="fi-arrow-long-right me-2" />Crea tu cuenta <b>FotoHunters</b></a>
            </div>
          </div>
          <br/>
          <br/>
          <br/>

        </section>
      

 
        {/* Services*/}

        <Footer></Footer>
      </div>
      </main>

        </div>
      );

    }
  }


export default Explicacion;
