const municipios = [
	{
		"barrio_codigo": "0796117",
		"barrio_nombre": "Abrantes",
		"distrito_codigo": "079611",
		"distrito_nombre": "Carabanchel",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.56,
		"densidad_por_km2": 18844.87
	},
	{
		"barrio_codigo": "0796032",
		"barrio_nombre": "Adelfas",
		"distrito_codigo": "079603",
		"distrito_nombre": "Retiro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.61,
		"densidad_por_km2": 29750.82
	},
	{
		"barrio_codigo": "0796027",
		"barrio_nombre": "Atocha",
		"distrito_codigo": "079602",
		"distrito_nombre": "Arganzuela",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.79,
		"densidad_por_km2": 1401.27
	},
	{
		"barrio_codigo": "0796011",
		"barrio_nombre": "Barrio Salamanca",
		"distrito_codigo": "079601",
		"distrito_nombre": "Centro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.46,
		"densidad_por_km2": 15323.29
	},
	{
		"barrio_codigo": "0796046",
		"barrio_nombre": "Castellana",
		"distrito_codigo": "079604",
		"distrito_nombre": "Salamanca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.77,
		"densidad_por_km2": 22090.91
	},
	{
		"barrio_codigo": "0796056",
		"barrio_nombre": "Castilla",
		"distrito_codigo": "079605",
		"distrito_nombre": "Chamartín",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.13,
		"densidad_por_km2": 7789.67
	},
	{
		"barrio_codigo": "0796053",
		"barrio_nombre": "Ciudad Jardín",
		"distrito_codigo": "079605",
		"distrito_nombre": "Chamartín",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.77,
		"densidad_por_km2": 23794.81
	},
	{
		"barrio_codigo": "0796013",
		"barrio_nombre": "Cortes",
		"distrito_codigo": "079601",
		"distrito_nombre": "Centro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.59,
		"densidad_por_km2": 17850.85
	},
	{
		"barrio_codigo": "0796025",
		"barrio_nombre": "Delicias",
		"distrito_codigo": "079602",
		"distrito_nombre": "Arganzuela",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.07,
		"densidad_por_km2": 25485.05
	},
	{
		"barrio_codigo": "0796012",
		"barrio_nombre": "Embajadores",
		"distrito_codigo": "079601",
		"distrito_nombre": "Centro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.03,
		"densidad_por_km2": 43345.63
	},
	{
		"barrio_codigo": "0796033",
		"barrio_nombre": "Estrella",
		"distrito_codigo": "079603",
		"distrito_nombre": "Retiro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.02,
		"densidad_por_km2": 22701.96
	},
	{
		"barrio_codigo": "0796043",
		"barrio_nombre": "Fuente del Berro",
		"distrito_codigo": "079604",
		"distrito_nombre": "Salamanca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.83,
		"densidad_por_km2": 24812.05
	},
	{
		"barrio_codigo": "0796042",
		"barrio_nombre": "Goya",
		"distrito_codigo": "079604",
		"distrito_nombre": "Salamanca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.77,
		"densidad_por_km2": 38016.88
	},
	{
		"barrio_codigo": "0796044",
		"barrio_nombre": "Guindalera",
		"distrito_codigo": "079604",
		"distrito_nombre": "Salamanca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.58,
		"densidad_por_km2": 25740.51
	},
	{
		"barrio_codigo": "0796034",
		"barrio_nombre": "Ibiza",
		"distrito_codigo": "079603",
		"distrito_nombre": "Retiro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.5,
		"densidad_por_km2": 42698
	},
	{
		"barrio_codigo": "0796011",
		"barrio_nombre": "Palacio",
		"distrito_codigo": "079601",
		"distrito_nombre": "Centro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.46,
		"densidad_por_km2": 15323.29
	},
	{
		"barrio_codigo": "0796011",
		"barrio_nombre": "Chamberi",
		"distrito_codigo": "079601",
		"distrito_nombre": "Centro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.46,
		"densidad_por_km2": 15323.29
	},
	{
		"barrio_codigo": "0796212",
		"barrio_nombre": "Aeropuerto",
		"distrito_codigo": "079621",
		"distrito_nombre": "Barajas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 19.87,
		"densidad_por_km2": 89.88
	},
	{
		"barrio_codigo": "0796211",
		"barrio_nombre": "Alameda de Osuna",
		"distrito_codigo": "079621",
		"distrito_nombre": "Barajas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.98,
		"densidad_por_km2": 9795.45
	},
	{
		"barrio_codigo": "0796074",
		"barrio_nombre": "Almagro",
		"distrito_codigo": "079607",
		"distrito_nombre": "Chamberí",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.96,
		"densidad_por_km2": 20517.71
	},
	{
		"barrio_codigo": "0796064",
		"barrio_nombre": "Almenara",
		"distrito_codigo": "079606",
		"distrito_nombre": "Tetuan",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.99,
		"densidad_por_km2": 22232.32
	},
	{
		"barrio_codigo": "0796124",
		"barrio_nombre": "Almendrales",
		"distrito_codigo": "079612",
		"distrito_nombre": "Usera",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.75,
		"densidad_por_km2": 27480
	},
	{
		"barrio_codigo": "0796104",
		"barrio_nombre": "Aluche",
		"distrito_codigo": "079610",
		"distrito_nombre": "Latina",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.86,
		"densidad_por_km2": 22839.86
	},
	{
		"barrio_codigo": "0796192",
		"barrio_nombre": "Ambroz",
		"distrito_codigo": "079619",
		"distrito_nombre": "Vicílvaro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.43,
		"densidad_por_km2": 7598.77
	},
	{
		"barrio_codigo": "0796203",
		"barrio_nombre": "Amposta",
		"distrito_codigo": "079620",
		"distrito_nombre": "San Blas-Canillejas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.37,
		"densidad_por_km2": 22213.51
	},
	{
		"barrio_codigo": "0796165",
		"barrio_nombre": "Apostol Santiago",
		"distrito_codigo": "079616",
		"distrito_nombre": "Hortaleza",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.2,
		"densidad_por_km2": 12627.5
	},
	{
		"barrio_codigo": "0796072",
		"barrio_nombre": "Arapiles",
		"distrito_codigo": "079607",
		"distrito_nombre": "Chamberí",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.57,
		"densidad_por_km2": 42435.09
	},
	{
		"barrio_codigo": "0796097",
		"barrio_nombre": "Aravaca",
		"distrito_codigo": "079609",
		"distrito_nombre": "Moncloa-Aravaca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 5.86,
		"densidad_por_km2": 4384.3
	},
	{
		"barrio_codigo": "0796204",
		"barrio_nombre": "Arcos",
		"distrito_codigo": "079620",
		"distrito_nombre": "San Blas-Canillejas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.31,
		"densidad_por_km2": 18129.77
	},
	{
		"barrio_codigo": "0796092",
		"barrio_nombre": "Argíelles",
		"distrito_codigo": "079609",
		"distrito_nombre": "Moncloa-Aravaca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.77,
		"densidad_por_km2": 31062.34
	},
	{
		"barrio_codigo": "0796158",
		"barrio_nombre": "Atalaya",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.25,
		"densidad_por_km2": 6240
	},
	{
		"barrio_codigo": "0796061",
		"barrio_nombre": "Bellas Vistas",
		"distrito_codigo": "079606",
		"distrito_nombre": "Tetuan",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.73,
		"densidad_por_km2": 38923.29
	},
	{
		"barrio_codigo": "0796066",
		"barrio_nombre": "Berruguete",
		"distrito_codigo": "079606",
		"distrito_nombre": "Tetuan",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.6,
		"densidad_por_km2": 40533.33
	},
	{
		"barrio_codigo": "0796116",
		"barrio_nombre": "Buenavista",
		"distrito_codigo": "079611",
		"distrito_nombre": "Carabanchel",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 5.6,
		"densidad_por_km2": 8080.36
	},
	{
		"barrio_codigo": "0796173",
		"barrio_nombre": "Butarque",
		"distrito_codigo": "079617",
		"distrito_nombre": "Villaverde",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 6.37,
		"densidad_por_km2": 2706.59
	},
	{
		"barrio_codigo": "0796105",
		"barrio_nombre": "Campamento",
		"distrito_codigo": "079610",
		"distrito_nombre": "Latina",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 9.16,
		"densidad_por_km2": 2050.22
	},
	{
		"barrio_codigo": "0796163",
		"barrio_nombre": "Canillas",
		"distrito_codigo": "079616",
		"distrito_nombre": "Hortaleza",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.56,
		"densidad_por_km2": 15820.31
	},
	{
		"barrio_codigo": "0796207",
		"barrio_nombre": "Canillejas",
		"distrito_codigo": "079620",
		"distrito_nombre": "San Blas-Canillejas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.63,
		"densidad_por_km2": 17429.45
	},
	{
		"barrio_codigo": "0796091",
		"barrio_nombre": "Casa de Campo",
		"distrito_codigo": "079609",
		"distrito_nombre": "Moncloa-Aravaca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 17.44,
		"densidad_por_km2": 735.95
	},
	{
		"barrio_codigo": "0796213",
		"barrio_nombre": "Casco Histírico de Barajas",
		"distrito_codigo": "079621",
		"distrito_nombre": "Barajas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.64,
		"densidad_por_km2": 11415.62
	},
	{
		"barrio_codigo": "0796181",
		"barrio_nombre": "Casco Histírico de Vallecas",
		"distrito_codigo": "079618",
		"distrito_nombre": "Villa de Vallecas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 49.45,
		"densidad_por_km2": 1622.73
	},
	{
		"barrio_codigo": "0796191",
		"barrio_nombre": "Casco Histírico de Vicílvaro",
		"distrito_codigo": "079619",
		"distrito_nombre": "Vicílvaro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 32.93,
		"densidad_por_km2": 1566.57
	},
	{
		"barrio_codigo": "0796063",
		"barrio_nombre": "Castillejos",
		"distrito_codigo": "079606",
		"distrito_nombre": "Tetuan",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.69,
		"densidad_por_km2": 28962.32
	},
	{
		"barrio_codigo": "0796093",
		"barrio_nombre": "Ciudad Universitaria",
		"distrito_codigo": "079609",
		"distrito_nombre": "Moncloa-Aravaca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 14.14,
		"densidad_por_km2": 1135.01
	},
	{
		"barrio_codigo": "0796157",
		"barrio_nombre": "Colina",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.56,
		"densidad_por_km2": 10932.14
	},
	{
		"barrio_codigo": "0796111",
		"barrio_nombre": "Comillas",
		"distrito_codigo": "079611",
		"distrito_nombre": "Carabanchel",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.67,
		"densidad_por_km2": 33000
	},
	{
		"barrio_codigo": "0796154",
		"barrio_nombre": "Concepciín",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.9,
		"densidad_por_km2": 22828.89
	},
	{
		"barrio_codigo": "0796215",
		"barrio_nombre": "Corralejos",
		"distrito_codigo": "079621",
		"distrito_nombre": "Barajas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 4.67,
		"densidad_por_km2": 1577.09
	},
	{
		"barrio_codigo": "0796159",
		"barrio_nombre": "Costillares",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.44,
		"densidad_por_km2": 15230.56
	},
	{
		"barrio_codigo": "0796062",
		"barrio_nombre": "Cuatro Caminos",
		"distrito_codigo": "079606",
		"distrito_nombre": "Tetuan",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.19,
		"densidad_por_km2": 28535.29
	},
	{
		"barrio_codigo": "0796106",
		"barrio_nombre": "Cuatro Vientos",
		"distrito_codigo": "079610",
		"distrito_nombre": "Latina",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 5.44,
		"densidad_por_km2": 1003.13
	},
	{
		"barrio_codigo": "0796088",
		"barrio_nombre": "El Goloso",
		"distrito_codigo": "079608",
		"distrito_nombre": "Fuencarral-El Pardo",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 26.38,
		"densidad_por_km2": 658.98
	},
	{
		"barrio_codigo": "0796081",
		"barrio_nombre": "El Pardo",
		"distrito_codigo": "079608",
		"distrito_nombre": "Fuencarral-El Pardo",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 187.77,
		"densidad_por_km2": 18.09
	},
	{
		"barrio_codigo": "0796084",
		"barrio_nombre": "El Pilar",
		"distrito_codigo": "079608",
		"distrito_nombre": "Fuencarral-El Pardo",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.37,
		"densidad_por_km2": 33451.82
	},
	{
		"barrio_codigo": "0796096",
		"barrio_nombre": "El Plantío",
		"distrito_codigo": "079609",
		"distrito_nombre": "Moncloa-Aravaca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 3.56,
		"densidad_por_km2": 764.89
	},
	{
		"barrio_codigo": "0796208",
		"barrio_nombre": "El Salvador",
		"distrito_codigo": "079620",
		"distrito_nombre": "San Blas-Canillejas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.86,
		"densidad_por_km2": 6112.9
	},
	{
		"barrio_codigo": "0796051",
		"barrio_nombre": "El Viso",
		"distrito_codigo": "079605",
		"distrito_nombre": "Chamartín",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.72,
		"densidad_por_km2": 9911.63
	},
	{
		"barrio_codigo": "0796131",
		"barrio_nombre": "Entrevías",
		"distrito_codigo": "079613",
		"distrito_nombre": "Puente de Vallecas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 5.95,
		"densidad_por_km2": 5636.97
	},
	{
		"barrio_codigo": "0796145",
		"barrio_nombre": "Fontarrín",
		"distrito_codigo": "079614",
		"distrito_nombre": "Moratalaz",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.98,
		"densidad_por_km2": 17044.9
	},
	{
		"barrio_codigo": "0796082",
		"barrio_nombre": "Fuentelarreina",
		"distrito_codigo": "079608",
		"distrito_nombre": "Fuencarral-El Pardo",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.51,
		"densidad_por_km2": 2113.91
	},
	{
		"barrio_codigo": "0796071",
		"barrio_nombre": "Gaztambide",
		"distrito_codigo": "079607",
		"distrito_nombre": "Chamberí",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.49,
		"densidad_por_km2": 45732.65
	},
	{
		"barrio_codigo": "0796202",
		"barrio_nombre": "Hellín",
		"distrito_codigo": "079620",
		"distrito_nombre": "San Blas-Canillejas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.56,
		"densidad_por_km2": 15773.21
	},
	{
		"barrio_codigo": "0796054",
		"barrio_nombre": "Hispanoamírica",
		"distrito_codigo": "079605",
		"distrito_nombre": "Chamartín",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.71,
		"densidad_por_km2": 18266.67
	},
	{
		"barrio_codigo": "0796142",
		"barrio_nombre": "Horcajo",
		"distrito_codigo": "079614",
		"distrito_nombre": "Moratalaz",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.73,
		"densidad_por_km2": 8815.07
	},
	{
		"barrio_codigo": "0796021",
		"barrio_nombre": "Imperial",
		"distrito_codigo": "079602",
		"distrito_nombre": "Arganzuela",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.98,
		"densidad_por_km2": 23105.1
	},
	{
		"barrio_codigo": "0796035",
		"barrio_nombre": "Jerínimos",
		"distrito_codigo": "079603",
		"distrito_nombre": "Retiro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.89,
		"densidad_por_km2": 3730.69
	},
	{
		"barrio_codigo": "0796014",
		"barrio_nombre": "Justicia",
		"distrito_codigo": "079601",
		"distrito_nombre": "Centro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.75,
		"densidad_por_km2": 21866.67
	},
	{
		"barrio_codigo": "0796023",
		"barrio_nombre": "La Chopera",
		"distrito_codigo": "079602",
		"distrito_nombre": "Arganzuela",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.56,
		"densidad_por_km2": 35276.79
	},
	{
		"barrio_codigo": "0796085",
		"barrio_nombre": "La Paz",
		"distrito_codigo": "079608",
		"distrito_nombre": "Fuencarral-El Pardo",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.17,
		"densidad_por_km2": 15578.8
	},
	{
		"barrio_codigo": "0796022",
		"barrio_nombre": "Las Acacias",
		"distrito_codigo": "079602",
		"distrito_nombre": "Arganzuela",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.1,
		"densidad_por_km2": 33318.18
	},
	{
		"barrio_codigo": "0796107",
		"barrio_nombre": "Las Aguilas",
		"distrito_codigo": "079610",
		"distrito_nombre": "Latina",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 3.63,
		"densidad_por_km2": 14038.84
	},
	{
		"barrio_codigo": "0796024",
		"barrio_nombre": "Legazpi",
		"distrito_codigo": "079602",
		"distrito_nombre": "Arganzuela",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.37,
		"densidad_por_km2": 13826.28
	},
	{
		"barrio_codigo": "0796045",
		"barrio_nombre": "Lista",
		"distrito_codigo": "079604",
		"distrito_nombre": "Salamanca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.54,
		"densidad_por_km2": 38435.19
	},
	{
		"barrio_codigo": "0796175",
		"barrio_nombre": "Los Angeles",
		"distrito_codigo": "079617",
		"distrito_nombre": "Villaverde",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.14,
		"densidad_por_km2": 14144.86
	},
	{
		"barrio_codigo": "0796101",
		"barrio_nombre": "Los Círmenes",
		"distrito_codigo": "079610",
		"distrito_nombre": "Latina",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.3,
		"densidad_por_km2": 13116.15
	},
	{
		"barrio_codigo": "0796174",
		"barrio_nombre": "Los Rosales",
		"distrito_codigo": "079617",
		"distrito_nombre": "Villaverde",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.51,
		"densidad_por_km2": 24163.58
	},
	{
		"barrio_codigo": "0796103",
		"barrio_nombre": "Lucero",
		"distrito_codigo": "079610",
		"distrito_nombre": "Latina",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.7,
		"densidad_por_km2": 20756.47
	},
	{
		"barrio_codigo": "0796143",
		"barrio_nombre": "Marroquina",
		"distrito_codigo": "079614",
		"distrito_nombre": "Moratalaz",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.75,
		"densidad_por_km2": 15633.71
	},
	{
		"barrio_codigo": "0796144",
		"barrio_nombre": "Media Legua",
		"distrito_codigo": "079614",
		"distrito_nombre": "Moratalaz",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.02,
		"densidad_por_km2": 17466.67
	},
	{
		"barrio_codigo": "0796087",
		"barrio_nombre": "Mirasierra",
		"distrito_codigo": "079608",
		"distrito_nombre": "Fuencarral-El Pardo",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 6.91,
		"densidad_por_km2": 4465.7
	},
	{
		"barrio_codigo": "0796125",
		"barrio_nombre": "Moscardí",
		"distrito_codigo": "079612",
		"distrito_nombre": "Usera",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.9,
		"densidad_por_km2": 28230
	},
	{
		"barrio_codigo": "0796036",
		"barrio_nombre": "Niío Jesís",
		"distrito_codigo": "079603",
		"distrito_nombre": "Retiro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.64,
		"densidad_por_km2": 23706.25
	},
	{
		"barrio_codigo": "0796055",
		"barrio_nombre": "Nueva España",
		"distrito_codigo": "079605",
		"distrito_nombre": "Chamartín",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.78,
		"densidad_por_km2": 13539.33
	},
	{
		"barrio_codigo": "0796136",
		"barrio_nombre": "Numancia",
		"distrito_codigo": "079613",
		"distrito_nombre": "Puente de Vallecas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.82,
		"densidad_por_km2": 25219.78
	},
	{
		"barrio_codigo": "0796112",
		"barrio_nombre": "Opaíel",
		"distrito_codigo": "079611",
		"distrito_nombre": "Carabanchel",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.14,
		"densidad_por_km2": 28110.53
	},
	{
		"barrio_codigo": "0796121",
		"barrio_nombre": "Orcasitas",
		"distrito_codigo": "079612",
		"distrito_nombre": "Usera",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.33,
		"densidad_por_km2": 16845.11
	},
	{
		"barrio_codigo": "0796122",
		"barrio_nombre": "Orcasur",
		"distrito_codigo": "079612",
		"distrito_nombre": "Usera",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.42,
		"densidad_por_km2": 9391.55
	},
	{
		"barrio_codigo": "0796031",
		"barrio_nombre": "Pacífico",
		"distrito_codigo": "079603",
		"distrito_nombre": "Retiro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.76,
		"densidad_por_km2": 44271.05
	},
	{
		"barrio_codigo": "0796161",
		"barrio_nombre": "Palomas",
		"distrito_codigo": "079616",
		"distrito_nombre": "Hortaleza",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.14,
		"densidad_por_km2": 5859.65
	},
	{
		"barrio_codigo": "0796133",
		"barrio_nombre": "Palomeras Bajas",
		"distrito_codigo": "079613",
		"distrito_nombre": "Puente de Vallecas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.72,
		"densidad_por_km2": 22875.58
	},
	{
		"barrio_codigo": "0796134",
		"barrio_nombre": "Palomeras Sureste",
		"distrito_codigo": "079613",
		"distrito_nombre": "Puente de Vallecas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 3.05,
		"densidad_por_km2": 13777.05
	},
	{
		"barrio_codigo": "0796026",
		"barrio_nombre": "Palos de Moguer",
		"distrito_codigo": "079602",
		"distrito_nombre": "Arganzuela",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.65,
		"densidad_por_km2": 39372.31
	},
	{
		"barrio_codigo": "0796141",
		"barrio_nombre": "Pavones",
		"distrito_codigo": "079614",
		"distrito_nombre": "Moratalaz",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1,
		"densidad_por_km2": 9118
	},
	{
		"barrio_codigo": "0796083",
		"barrio_nombre": "Peía Grande",
		"distrito_codigo": "079608",
		"distrito_nombre": "Fuencarral-El Pardo",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.86,
		"densidad_por_km2": 15476.92
	},
	{
		"barrio_codigo": "0796164",
		"barrio_nombre": "Pinar del Rey",
		"distrito_codigo": "079616",
		"distrito_nombre": "Hortaleza",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.64,
		"densidad_por_km2": 19682.95
	},
	{
		"barrio_codigo": "0796162",
		"barrio_nombre": "Piovera",
		"distrito_codigo": "079616",
		"distrito_nombre": "Hortaleza",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 3.14,
		"densidad_por_km2": 4601.91
	},
	{
		"barrio_codigo": "0796135",
		"barrio_nombre": "Portazgo",
		"distrito_codigo": "079613",
		"distrito_nombre": "Puente de Vallecas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.24,
		"densidad_por_km2": 22310.48
	},
	{
		"barrio_codigo": "0796127",
		"barrio_nombre": "Pradolongo",
		"distrito_codigo": "079612",
		"distrito_nombre": "Usera",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.09,
		"densidad_por_km2": 15475.23
	},
	{
		"barrio_codigo": "0796052",
		"barrio_nombre": "Prosperidad",
		"distrito_codigo": "079605",
		"distrito_nombre": "Chamartín",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.01,
		"densidad_por_km2": 35740.59
	},
	{
		"barrio_codigo": "0796152",
		"barrio_nombre": "Pueblo Nuevo",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.34,
		"densidad_por_km2": 25838.89
	},
	{
		"barrio_codigo": "0796115",
		"barrio_nombre": "Puerta Bonita",
		"distrito_codigo": "079611",
		"distrito_nombre": "Carabanchel",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.6,
		"densidad_por_km2": 20632.5
	},
	{
		"barrio_codigo": "0796102",
		"barrio_nombre": "Puerta del Angel",
		"distrito_codigo": "079610",
		"distrito_nombre": "Latina",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.38,
		"densidad_por_km2": 29748.55
	},
	{
		"barrio_codigo": "0796153",
		"barrio_nombre": "Quintana",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.71,
		"densidad_por_km2": 34056.34
	},
	{
		"barrio_codigo": "0796041",
		"barrio_nombre": "Recoletos",
		"distrito_codigo": "079604",
		"distrito_nombre": "Salamanca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.87,
		"densidad_por_km2": 17828.74
	},
	{
		"barrio_codigo": "0796206",
		"barrio_nombre": "Rejas",
		"distrito_codigo": "079620",
		"distrito_nombre": "San Blas-Canillejas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 4.98,
		"densidad_por_km2": 3165.66
	},
	{
		"barrio_codigo": "0796205",
		"barrio_nombre": "Rosas",
		"distrito_codigo": "079620",
		"distrito_nombre": "San Blas-Canillejas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 9.26,
		"densidad_por_km2": 3403.02
	},
	{
		"barrio_codigo": "0796075",
		"barrio_nombre": "Ríos Rosas",
		"distrito_codigo": "079607",
		"distrito_nombre": "Chamberí",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.01,
		"densidad_por_km2": 26544.55
	},
	{
		"barrio_codigo": "0796171",
		"barrio_nombre": "San Andrís",
		"distrito_codigo": "079617",
		"distrito_nombre": "Villaverde",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 9.1,
		"densidad_por_km2": 4777.25
	},
	{
		"barrio_codigo": "0796172",
		"barrio_nombre": "San Cristobal",
		"distrito_codigo": "079617",
		"distrito_nombre": "Villaverde",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.09,
		"densidad_por_km2": 13939.45
	},
	{
		"barrio_codigo": "0796132",
		"barrio_nombre": "San Diego",
		"distrito_codigo": "079613",
		"distrito_nombre": "Puente de Vallecas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.06,
		"densidad_por_km2": 37027.36
	},
	{
		"barrio_codigo": "0796123",
		"barrio_nombre": "San Fermín",
		"distrito_codigo": "079612",
		"distrito_nombre": "Usera",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.44,
		"densidad_por_km2": 15936.11
	},
	{
		"barrio_codigo": "0796113",
		"barrio_nombre": "San Isidro",
		"distrito_codigo": "079611",
		"distrito_nombre": "Carabanchel",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.9,
		"densidad_por_km2": 19997.89
	},
	{
		"barrio_codigo": "0796156",
		"barrio_nombre": "San Juan Bautista",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.04,
		"densidad_por_km2": 11888.46
	},
	{
		"barrio_codigo": "0796155",
		"barrio_nombre": "San Pascual",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.06,
		"densidad_por_km2": 17079.25
	},
	{
		"barrio_codigo": "0796182",
		"barrio_nombre": "Santa Eugenia",
		"distrito_codigo": "079618",
		"distrito_nombre": "Villa de Vallecas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.04,
		"densidad_por_km2": 11822.06
	},
	{
		"barrio_codigo": "0796201",
		"barrio_nombre": "Simancas",
		"distrito_codigo": "079620",
		"distrito_nombre": "San Blas-Canillejas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 2.29,
		"densidad_por_km2": 11572.05
	},
	{
		"barrio_codigo": "0796016",
		"barrio_nombre": "Sol",
		"distrito_codigo": "079601",
		"distrito_nombre": "Centro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.45,
		"densidad_por_km2": 16304.44
	},
	{
		"barrio_codigo": "0796214",
		"barrio_nombre": "Timín",
		"distrito_codigo": "079621",
		"distrito_nombre": "Barajas",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 16.4,
		"densidad_por_km2": 672.01
	},
	{
		"barrio_codigo": "0796073",
		"barrio_nombre": "Trafalgar",
		"distrito_codigo": "079607",
		"distrito_nombre": "Chamberí",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.62,
		"densidad_por_km2": 39561.29
	},
	{
		"barrio_codigo": "0796015",
		"barrio_nombre": "Universidad",
		"distrito_codigo": "079601",
		"distrito_nombre": "Centro",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.93,
		"densidad_por_km2": 33051.61
	},
	{
		"barrio_codigo": "0796065",
		"barrio_nombre": "Valdeacederas",
		"distrito_codigo": "079606",
		"distrito_nombre": "Tetuan",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.17,
		"densidad_por_km2": 21574.36
	},
	{
		"barrio_codigo": "0796166",
		"barrio_nombre": "Valdefuentes",
		"distrito_codigo": "079616",
		"distrito_nombre": "Hortaleza",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 15.19,
		"densidad_por_km2": 3400.53
	},
	{
		"barrio_codigo": "0796095",
		"barrio_nombre": "Valdemarín",
		"distrito_codigo": "079609",
		"distrito_nombre": "Moncloa-Aravaca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 3.26,
		"densidad_por_km2": 1966.26
	},
	{
		"barrio_codigo": "0796094",
		"barrio_nombre": "Valdezarza",
		"distrito_codigo": "079609",
		"distrito_nombre": "Moncloa-Aravaca",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.44,
		"densidad_por_km2": 20317.36
	},
	{
		"barrio_codigo": "0796076",
		"barrio_nombre": "Vallehermoso",
		"distrito_codigo": "079607",
		"distrito_nombre": "Chamberí",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.08,
		"densidad_por_km2": 18306.48
	},
	{
		"barrio_codigo": "0796086",
		"barrio_nombre": "Valverde",
		"distrito_codigo": "079608",
		"distrito_nombre": "Fuencarral-El Pardo",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 9.03,
		"densidad_por_km2": 6642.86
	},
	{
		"barrio_codigo": "0796151",
		"barrio_nombre": "Ventas",
		"distrito_codigo": "079615",
		"distrito_nombre": "Ciudad Lineal",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 3.22,
		"densidad_por_km2": 14700.93
	},
	{
		"barrio_codigo": "0796146",
		"barrio_nombre": "Vinateros",
		"distrito_codigo": "079614",
		"distrito_nombre": "Moratalaz",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.6,
		"densidad_por_km2": 27948.33
	},
	{
		"barrio_codigo": "0796114",
		"barrio_nombre": "Vista Alegre",
		"distrito_codigo": "079611",
		"distrito_nombre": "Carabanchel",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 1.63,
		"densidad_por_km2": 27212.27
	},
	{
		"barrio_codigo": "0796126",
		"barrio_nombre": "Zofío",
		"distrito_codigo": "079612",
		"distrito_nombre": "Usera",
		"municipio_codigo": "0796",
		"municipio_nombre": "Madrid",
		"superficie_km2": 0.77,
		"densidad_por_km2": 17466.23
	}
]
export default municipios;
