import React from "react";

import { auth , db } from "../firebase";

import "../App.css";


import 'firebase/storage'

import Swal from 'sweetalert2'


import municipios from "../Shared/Municipios";


import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Email from "../assets/email.png";

class CrearInforme extends React.Component{
  

  
  constructor(props) {
    super(props) 
    this.state = {
        step: 5,
        operacion: "Venta",
        precio: 0,
        calle: "",
        descripcion: "",
        nBaños: 0,
        nHabitaciones: 1,
        visitas: 0,
        inmobiliaria: false,
        images: ["https://www.yaencontre.com/noticias/wp-content/uploads/2018/03/Casa-en-Madrid.jpg"],
        fecha: Date.now(),
        particular: true,
        visitas: 0,
        imagesPlace: "https://firebasestorage.googleapis.com/v0/b/fotohunters-com.appspot.com/o/01.jpg?alt=media&token=2193b160-d6bb-4eb8-8c58-e603cbcb8fbe"
    }
  }


  handleChangeZonas = (event) => {
    this.setState({zona: event.target.value});
  }

  addImage(newItem) {
    if(this.state.images[0] === "https://firebasestorage.googleapis.com/v0/b/fotohunters-com.appspot.com/o/01.jpg?alt=media&token=2193b160-d6bb-4eb8-8c58-e603cbcb8fbe") {
      this.setState({images: [newItem]})
    } else {
      this.setState({images: [...this.state.images, newItem]})
    }
  }

  removeImage(e) {
    var arr = this.state.images
    arr = arr.filter(function(item) {
      return item !== e
   })
   this.setState({images: arr});

  }

  renderImagenes() {

    if( this.state.images[0] !== this.state.imagesPlace) {
      return this.state.images.map( (item, i) => {
        return (
          <div className="col-4 mt-2" >
          <img className="thumbnailImg" src={ item}></img>
          <i className="fi-trash text-primary fs-5 mt-n1 me-2 posImg" onClick={() => this.removeImage(item)} />
          </div>
        )
      }
       
        )
      
    }
 
  }

  renderZonas() {
    var array = []
    var i;
    var data = municipios;
    for(i=0;i < data.length ;i++) {
      array.push(
        <option value={data[i].barrio_nombre}>{data[i].barrio_nombre}</option>
      )
    }
    return array;
  }

  async publicar() {
      if(auth.currentUser) {
        let id = "id-part-" + Date.now();
        this.setState({id: id})
        await db.collection("activos-2023").doc(id).set(this.state);
        await db.collection("users").doc(auth.currentUser.email).collection("activos").doc(id).set(this.state);

        Swal.fire(
          '¡Inmueble subido correctamente!',
          'Se ha añadido el inmueble a FotoHunters',
          'success'
        )
      }
   
     
  }

  renderSteps() {
    
    var step = this.state.step;
    if( step === 0) {
      return(
        <>
          {/* Basic info*/}
        <section className="card card-body border-0 shadow-sm p-4 mb-4 " id="basic-info">
                <h2 className="h4 mb-4"><i className="fi-info-circle text-primary fs-5 mt-n1 me-2" />Información basica</h2>
                <div className="mb-3">
                  <label className="form-label" htmlFor="ap-title">Nombre de tu projecto <span className="text-danger">*</span></label>
                  <input className="form-control" type="text" id="ap-title" value={this.state.descripcion} placeholder="Restaurante vegano..." required onChange={(e) =>  this.setState({ descripcion : e.target.value})}/>
                </div>


                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="ap-city">Selecciona categoria de negocio<span className="text-danger">*</span></label>
                  <select className="form-select" id="ap-city" required>
                  
                    <option value="Cafeterías">Cafeterías</option>
                    <option value="Tintorerías">Tintorerías</option>
                    <option value="Papelerías">Papelerías</option>
                    <option value="Ferreterías">Ferreterías</option>
                    <option value="Madrid">Restaurantes</option>
                    <option value="Madrid">Ocio</option>
                    <option value="Madrid">Tetería</option>
                    <option value="Madrid">Entretenimiento</option>
                    <option value="Madrid">Tienda</option>


                  </select>
                </div>
             
              </section>
        </> 
      )
    }
    if( step === 1) {
      return (
        <>
          {/* Location*/}
          <section className="card card-body border-0 shadow-sm p-4 mb-4" id="location">
              <h2 className="h4 mb-4"><i className="fi-map-pin text-primary fs-5 mt-n1 me-2" />Localización</h2>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="ap-country">Pais <span className="text-danger">*</span></label>
                  <select className="form-select" id="ap-country" required>
                    <option value disabled>FotoHunters solo esta disponible en España</option>
                    <option value >España</option>

                    
                  </select>
                </div>
                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="ap-city">Ciudad <span className="text-danger">*</span></label>
                  <select className="form-select" id="ap-city" required>
                    <option value disabled>FotoHunters solo esta disponible en Madrid</option>
                    <option value="Madrid">Madrid</option>
                  </select>
                </div>
              </div>
            
              
              <div className="row">
                <div className="col-sm-6 mb-3">
                <select className="form-select"   value={this.state.zona} onChange={this.handleChangeZonas}>
                 <option>Selecciona zona de Madrid para tu negocio</option>
                  {this.renderZonas()}
                </select>
                </div>
              </div>
        
            </section>
          </>
      )
    }
    if( step === 2) {
      return (
        <>
          {/* Property details*/}

          <section className="card card-body border-0 shadow-sm p-4 mb-4" id="details">
            <h2 className="h4 mb-4"><i className="fi-edit text-primary fs-5 mt-n1 me-2" />Detalles propiedad</h2>
            <div className="mb-4" style={{maxWidth: '25rem'}}>
              <label className="form-label" htmlFor="ap-area">Metros cuadrados necesarios</label>
              <input  value={this.state.metros}  onChange={(e) =>  this.setState({ metros : e.target.value})}  className="form-control" type="number" id="ap-area" min={20} placeholder="230..." required />
            </div>
      
        
            <label className="form-label" htmlFor="ap-description">Añade requerimientos para tu local</label>
            <textarea  value={this.state.descripcionLong}  onChange={(e) =>  this.setState({ descripcionLong : e.target.value})}  className="form-control" id="ap-description" rows={5} placeholder="Describe brevemente lo que estás buscando" defaultValue={""} />
            {/* <span className="form-text">1500 characters left</span> */}
          </section></>
      )
    }
    if( step === 3) {
      return (
        <>
          {/* Price*/}
          <section className="card card-body border-0 shadow-sm p-4 mb-4" id="price">
            <h2 className="h4 mb-4"><i className="fi-cash text-primary fs-5 mt-n1 me-2" />Precio</h2>
            <label className="form-label" htmlFor="ap-price">Precio ideal para tu local <span className="text-danger">*</span></label>
            <div className="d-sm-flex">
         
              <input value={this.state.precio}  onChange={(e) =>  this.setState({ precio : e.target.value})} className="form-control w-100 me-2 mb-2" type="number" id="ap-price" min={200} step={50} required />
            
            </div>
          </section>
        </>
      )
    }
    if( step === 4) {
      return (
        <>
        {/* Contacts*/}
        <section className="card card-body border-0 shadow-sm p-4 mb-4" id="contacts">
          <h2 className="h4 mb-1"><i className="fi-phone text-primary fs-5 mt-n1 me-2" />Información contacto</h2>
          <span className="text-small mb-4">Añade la información en la que quieres que te contacten</span>

          <div className="row">
            <div className="col-sm-6 mb-3">
              <label className="form-label" htmlFor="ap-fn">Nombre <span className="text-danger">*</span></label>
              <input className="form-control" type="text" id="ap-fn"  placeholder="Nombre" required />
            </div>
            <div className="col-sm-6 mb-3">
              <label className="form-label" htmlFor="ap-sn">Apellidos<span className="text-danger">*</span></label>
              <input className="form-control" type="text" id="ap-sn"  placeholder="Apellidos" required />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 mb-1">
              <label className="form-label" htmlFor="ap-email">Email<span className="text-danger">*</span></label>
              <input className="form-control" type="text" id="ap-email"  placeholder="Email" />
            </div>
            <div className="col-sm-6 mb-1">
              <label className="form-label" htmlFor="ap-phone">Número de contacto <span className="text-danger">*</span></label>
              <input className="form-control" type="tel" id="ap-phone" data-format="custom" data-delimiter="-" data-blocks="3 3 4" placeholder="685 30 23 12" />
          <span className="form-text">Este será el numero el cual contactaran.</span>

            </div>
          </div>
         
        </section>
        </>
      )
    }
    if( step === 5) {
      return (
            <>
            <h4>¡Informe enviado!</h4>
            <img src={Email} className="img-fluid" width={"200px"} />
            <h6>Te enviaremos a tu email tu informe personalizado en los siguientes días.</h6>
            </>
      )
    }
  }


   render() {
        return(
        <div>
        <main className="page-wrapper mt-5">
          {/* Preview modal*/}
          <div className="modal fade" id="preview-modal mt-5" tabIndex={-1}>
            <div className="modal-dialog modal-fullscreen" role="document">
              <div className="modal-content">
                <div className="modal-header d-block d-sm-flex">
                  <h3 className="h5 text-muted fw-normal modal-title d-none d-sm-block">Visualización ficha</h3>
                  <div className="d-flex align-items-center">
                    <a className="btn btn-primary btn-sm me-4 minWidthbtn" onClick={() => this.publicar()}>Publicar </a>
                      <span className="fs-xs text-muted ms-auto me-2">Cerrar</span>
                    <button className="btn-close ms-0" type="button" data-bs-dismiss="modal" />
                  </div>
                </div>
                <div className="modal-body p-0">
                  <div className="container mt-2 mt-sm-0 py-4 py-sm-5">
                    <h1 className="h2 mb-2">{this.state.descripcion}</h1>
                    <p className="mb-2 pb-1 fs-lg">{this.state.calle}</p>
                    <ul className="d-flex mb-4 list-unstyled">
                      <li className="me-3 pe-3 border-end"><b className="me-1">{this.state.nHabitaciones}</b><i className="fi-bed mt-n1 lead align-middle text-muted" /></li>
                      <li className="me-3 pe-3 border-end"><b className="me-1">{this.state.nBaños}</b><i className="fi-bath mt-n1 lead align-middle text-muted" /></li>
                      <li className="me-3 pe-3 border-end"><b className="me-1">{this.state.parking}</b><i className="fi-car mt-n1 lead align-middle text-muted" /></li>
                      <li><b>{this.state.metros} </b>sq.m</li>
                    </ul>
                    <div className="" >
                      <div className="row g-2 g-md-3" style={{minWidth: '30rem'}}>
                      <div className="col-8"> {this.state.images[0] ? <img className="rounded rounded-md-3" src={this.state.images[0]} alt="Gallery thumbnail" /> : null }  </div>
                      <div className="col-4"> {this.state.images[1] ?  <img className="rounded rounded-md-3 mb-2 mb-md-3" src={this.state.images[1]} alt="Gallery thumbnail" /> : null } 
                      {this.state.images[2] ?  <img className="rounded rounded-md-3" src={this.state.images[2]} alt="Gallery thumbnail" /> : null } </div> 
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="col-md-7 mb-md-0 mb-4"><span className="badge bg-success me-2 mb-3">Verificado</span><span className="badge bg-info me-2 mb-3">Subido por particular</span>
                        <h2 className="h3 mb-4 pb-4 border-bottom">{this.state.precio}€<span className="d-inline-block ms-1 fs-base fw-normal text-body">{this.state.operacion ==="Venta" ? null : <>/mensual</>}</span></h2>
                        {/* Overview*/}
                        <div className="mb-4 pb-md-3">
                          <h3 className="h4">Descripción</h3>
                          <p className="mb-1">{this.state.descripcionLong}</p>
                          <div className="collapse" id="seeMoreOverview">
                            {/* <p className="mb-1">Asperiores eos molestias, aspernatur assumenda vel corporis ex, magni excepturi totam exercitationem quia inventore quod amet labore impedit quae distinctio? Officiis blanditiis consequatur alias, atque, sed est incidunt accusamus repudiandae tempora repellendus obcaecati delectus ducimus inventore tempore harum numquam autem eligendi culpa.</p> */}
                          </div>
                          {/* <a className="collapse-label collapsed" href="#seeMoreOverview" data-bs-toggle="collapse" data-bs-label-collapsed="Show more" data-bs-label-expanded="Show less" role="button" aria-expanded="false" aria-controls="seeMoreOverview" /> */}
                        </div>
                         {/* Property Details*/}
                          <div className="mb-4 pb-md-3">
                            <h3 className="h4">Detalles propiedad</h3>
                            <ul className="list-unstyled mb-0">
                              <li><b>Categoría: </b>{this.state.propiedad}</li>
                              <li><b>Tipo </b>{this.state.operacion}</li>
                              <li><b>Habitaciones: </b>{this.state.nHabitaciones}</li>
                              <li><b>Baños: </b>{this.state.nBaños}</li>
                            
                            </ul>
                          </div>
                        {/* Amenities*/}
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page container*/}
          <div className="container mt-0 mb-md-4 py-0 mt-5">
            <div className="row  mt-5">
              {/* Page content*/}
              <div className="col-lg-7 mt-3">
                {/* Breadcrumb*/}
                <nav className="mb-3 mt-5" aria-label="Breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/huntermaps">HunterMaps</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Informe de expansión</li>
                  </ol>
                </nav>
                {/* Title*/}
                <div className="mb-4">
                  <h1 className="h2 mb-0">Crea tu informe </h1>
                  <span className="text-small"><b>Fotohunters PRO</b> te ofrece la creación de informes de expansión y oferta de activos exclusivos para empezar tu negocio </span>

                  <div className="d-lg-none pt-3 mb-2">{} Información rellenada</div>
                  <div className="progress d-lg-none mb-4" style={{height: '.25rem'}}>
                    <div className="progress-bar bg-warning" role="progressbar" style={{width: '65%'}} aria-valuenow={65} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                </div>
              
           
         

                  {this.renderSteps()}
         
                {/* Action buttons */}
                <section className="d-sm-flex justify-content-between pt-2">
                {this.state.step !== 0 ?  <a  onClick={() => this.setState({step: this.state.step - 1})}className="btn btn-outline-primary btn-lg d-block ps-3 mb-3 mb-sm-2" data-bs-toggle="modal"><i className="fi-arrow-back me-2" />Atrás</a> : null}
                </section>
                
                <section className="d-sm-flex justify-content-between pt-2">
                  
                {/* <a className="btn btn-outline-primary btn-lg d-block ps-3 mb-3 mb-sm-2" href="#preview-modal" data-bs-toggle="modal"><i className="fi-eye-on me-2" />Previsualizar ficha activo</a> */}
               
                {this.state.step === 5 ? 
                  <Link to="/huntermaps" className="btn btn-primary btn-lg d-block mb-2"  >Volver a Huntersmaps</Link>
                  : 
                  <a className="btn btn-primary btn-lg d-block mb-2" onClick={() => this.setState({step: this.state.step + 1})} >Siguiente</a>
                  }
                  </section>
              </div>
              {/* Progress of completion*/}
              <aside className="col-lg-4 offset-lg-1 d-none d-lg-block">
                <div className="sticky-top pt-5">
                  <h6 className="pt-5 mt-3 mb-2">{((this.state.step ) * 20).toFixed(0)}% Completado</h6>
                  <div className="progress mb-4" style={{height:16.55}}>
                    <div className="progress-bar bg-warning" role="progressbar" style={{width: `${(this.state.step ) * 20}%` }} aria-valuenow={65} aria-valuemin={0} aria-valuemax={100} />
                  </div> 
                  <br/>
            
                </div>
              </aside>
            </div>
          </div>
        </main>
    
      </div>
                      
        )

    }

  }


export default CrearInforme;
