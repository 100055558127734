import React from "react";

import { auth , db } from "../firebase";

import "../App.css";


import 'firebase/storage'

import Swal from 'sweetalert2'


import DragDrop from "../Shared/DragDrop";


class Main extends React.Component{
  

  
  constructor(props) {
    super(props) 
    this.state = {
        step: 0,
        operacion: "Venta",
        precio: 0,
        calle: "",
        descripcion: "",
        nBaños: 0,
        nHabitaciones: 1,
        visitas: 0,
        inmobiliaria: false,
        images: [""],
        fecha: Date.now(),
        particular: true,
        visitas: 0,
        imagesPlace: "https://firebasestorage.googleapis.com/v0/b/fotohunters-com.appspot.com/o/01.jpg?alt=media&token=2193b160-d6bb-4eb8-8c58-e603cbcb8fbe"
    }
  }


  handleChangeZonas = (event) => {
    this.setState({zona: event.target.value});
  }

  addImage(newItem) {
    if(this.state.images[0] === "") {
      this.setState({images: [newItem]})
    } else {
      this.setState({images: [...this.state.images, newItem]})
    }
  }

  removeImage(e) {
    var arr = this.state.images
    arr = arr.filter(function(item) {
      return item !== e
   })
   this.setState({images: arr});

  }

  renderImagenes() {

    if( this.state.images[0] !== this.state.imagesPlace && this.state.images[0] != "") {
      return this.state.images.map( (item, i) => {
        return (
          <div className="col-4 mt-2" >
          <img className="thumbnailImg" src={ item}></img>
          <i className="fi-trash text-primary fs-5 mt-n1 me-2 posImg" onClick={() => this.removeImage(item)} />
          </div>
        )
      }
       
        )
      
    }
 
  }

  
  async publicar() {

        let id = "id-part-" + Date.now();
        this.setState({id: id, img: this.state.images[0]})
        await db.collection("activos-2024").doc(id).set(this.state);
        window.location = "activo/" + id

        Swal.fire(
          '¡Inmueble subido correctamente!',
          'Se ha añadido el inmueble a FotoHunters',
          'success'
        )
     
  }

  renderSteps() {
    
      return(
        <>

    <section className="card card-body border-0 shadow-sm p-4 mb-4" id="location">
      <h2 className="h4 mb-4"><i className="fi-map-pin text-primary fs-5 mt-n1 me-2" />Localización</h2>
      <div className="row">
        <div className="col-sm-6 mb-3">
          <label className="form-label" htmlFor="ap-country">Pais <span className="text-danger">*</span></label>
          <select className="form-select" id="ap-country" required>
            <option value disabled>FotoHunters solo esta disponible en España</option>
            <option value >España</option>

            
          </select>
        </div>
            <div className="col-sm-6 mb-3">
              <label className="form-label" htmlFor="ap-city">Ciudad <span className="text-danger">*</span></label>
              <select className="form-select" id="ap-city" required>
                <option value disabled>FotoHunters solo esta disponible en Madrid</option>
                <option value="Madrid">Madrid</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 mb-3">
            <label className="form-label" htmlFor="ap-address">Dirección <span className="text-danger">*</span></label>
            <input value={this.state.calle}  onChange={(e) =>  this.setState({ calle : e.target.value})} className="form-control" type="text" id="ap-address" placeholder="Avenida, Calle, Piso..." required />
        
            </div>
            <div className="col-sm-6 mb-3">
              <label className="form-label" htmlFor="ap-zip">Código postal<span className="text-danger">*</span></label>
              <input value={this.state.codigo}  onChange={(e) =>  this.setState({ codigo : e.target.value})}  className="form-control" type="number" id="ap-zip" placeholder="Enter Zip code"  required />
            </div>
          </div>
          


        </section>

        <section className="card card-body border-0 shadow-sm p-4 mb-4 " id="basic-info">
                <h2 className="h4 mb-4"><i className="fi-info-circle text-primary fs-5 mt-n1 me-2" />Información basica</h2>
                <div className="mb-3">
                  <label className="form-label" htmlFor="ap-title">Descripción corta <span className="text-danger">*</span></label>
                  <input className="form-control" type="text" id="ap-title" value={this.state.descripcion} placeholder="Local,  con licencia..." required onChange={(e) =>  this.setState({ descripcion : e.target.value})}/>
                </div>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <label className="form-label" htmlFor="ap-category">Tipo de operacion <span className="text-danger">*</span></label>
                    <select value={this.state.operacion}  onChange={(e) =>  this.setState({ operacion : e.target.value})} className="form-select" id="ap-category" required>
                      <option value="Alquiler">Alquiler</option>
                      <option value="Venta">Venta</option>

                    </select>
                  </div>
                  <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="ap-category">Precio <span className="text-danger">*</span></label>
                  <input value={this.state.precio}  onChange={(e) =>  this.setState({ precio : e.target.value})} className="form-control w-100 " type="number" id="ap-price" min={200} step={50} required />
                  </div>
                </div>

               
             
        </section>


   

        <section className="card card-body border-0 shadow-sm p-4 mb-4" id="details">
            <h2 className="h4 mb-4"><i className="fi-edit text-primary fs-5 mt-n1 me-2" />Detalles local</h2>
            <div className="mb-4" style={{maxWidth: '25rem'}}>
              <label className="form-label" htmlFor="ap-area">Metros cuadrados /m2</label>
              <input  value={this.state.metros}  onChange={(e) =>  this.setState({ metros : e.target.value})}  className="form-control" type="number" id="ap-area" min={20} placeholder="230..." required />
            </div>
            <div className="row">
          
            <div className="mb-3">
                  <label className="form-label" htmlFor="ap-title">Última licencia de actividad<span className="text-danger">*</span></label>
                  <input className="form-control" type="text" id="ap-title" value={this.state.actividad} placeholder="2023" required onChange={(e) =>  this.setState({ actividad : e.target.value})}/>
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="ap-title">Tipo Actividad<span className="text-danger">*</span></label>
                  <input className="form-control" type="text" id="ap-title" value={this.state.tipoActividad} placeholder="Recomendado restauración..." required onChange={(e) =>  this.setState({ tipoActividad : e.target.value})}/>
                </div>
             
         
            </div>
        
        
            <label className="form-label" htmlFor="ap-description">Descripción </label>
            <textarea  value={this.state.descripcionLong}  onChange={(e) =>  this.setState({ descripcionLong : e.target.value})}  className="form-control" id="ap-description" rows={5} placeholder="Describe el activo detallamente" defaultValue={""} />

          </section>

          <section className="card card-body border-0 shadow-sm p-4 mb-4" id="photos">
            <h2 className="h4 mb-4"><i className="fi-image text-primary fs-5 mt-n1 me-2" />Fotos / Videos</h2>
           
            <div className="row">
            <div className="col-12 col-md-6">
              <DragDrop onDone={(newItem) => this.addImage(newItem)}></DragDrop>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                {this.renderImagenes()}


                </div>
              </div>
            </div>
          </section>
      
          <section className="card card-body border-0 shadow-sm p-4 mb-4" id="contacts">
          <h2 className="h4 mb-1"><i className="fi-phone text-primary fs-5 mt-n1 me-2" />Información Hunter</h2>


          <div className="row">
            <div className="col-sm-6 mb-3">
              <label className="form-label" htmlFor="ap-fn">Nombre <span className="text-danger">*</span></label>
              <input className="form-control" type="text" id="ap-fn"  placeholder="Nombre" required />
            </div>
          
          </div>
         
       
        </section>
      </>
      )
    }
 
    // if( step ===3) {
    //   return (
    //     <>
    //       {/* Property details*/}

    //       <section className="card card-body border-0 shadow-sm p-4 mb-4" id="details">
    //         <h2 className="h4 mb-4"><i className="fi-edit text-primary fs-5 mt-n1 me-2" />Detalles propiedad</h2>
    //         <div className="mb-4" style={{maxWidth: '25rem'}}>
    //           <label className="form-label" htmlFor="ap-area">Metros cuadrados /m2</label>
    //           <input  value={this.state.metros}  onChange={(e) =>  this.setState({ metros : e.target.value})}  className="form-control" type="number" id="ap-area" min={20} placeholder="230..." required />
    //         </div>
    //         <div className="row">
          
           
         
    //         </div>
        
        
    //         <label className="form-label" htmlFor="ap-description">Descripción </label>
    //         <textarea  value={this.state.descripcionLong}  onChange={(e) =>  this.setState({ descripcionLong : e.target.value})}  className="form-control" id="ap-description" rows={5} placeholder="Describe el activo detallamente" defaultValue={""} />
    //         {/* <span className="form-text">1500 characters left</span> */}
    //       </section></>
    //   )
    // }

    // if( step ) {
    //   return (
    //     <>
    //      {/* Photos / video*/}
    //      <section className="card card-body border-0 shadow-sm p-4 mb-4" id="photos">
    //         <h2 className="h4 mb-4"><i className="fi-image text-primary fs-5 mt-n1 me-2" />Fotos / Videos</h2>
           
    //         <div className="row">
    //         <div className="col-12 col-md-6">
    //           <DragDrop onDone={(newItem) => this.addImage(newItem)}></DragDrop>
    //           </div>
    //           <div className="col-12 col-md-6">
    //             <div className="row">
    //             {this.renderImagenes()}


    //             </div>
    //           </div>
    //         </div>
    //       </section>
    //       </>
    //   )
    // }
    // if( step === 4) {
    //   return (
    //     <>
    //     {/* Contacts*/}
    //     <section className="card card-body border-0 shadow-sm p-4 mb-4" id="contacts">
    //       <h2 className="h4 mb-1"><i className="fi-phone text-primary fs-5 mt-n1 me-2" />Informacion Hunter</h2>


    //       <div className="row">
    //         <div className="col-sm-6 mb-3">
    //           <label className="form-label" htmlFor="ap-fn">Nombre <span className="text-danger">*</span></label>
    //           <input className="form-control" type="text" id="ap-fn"  placeholder="Nombre" required />
    //         </div>
          
    //       </div>
         
       
    //     </section>
    //     </>


   render() {
        return(
        <div>
        <main className="page-wrapper mt-5">
          {/* Preview modal*/}
          <div className="modal fade" id="preview-modal mt-5" tabIndex={-1}>
            <div className="modal-dialog modal-fullscreen" role="document">
              <div className="modal-content">
                <div className="modal-header d-block d-sm-flex">
                  <h3 className="h5 text-muted fw-normal modal-title d-none d-sm-block">Visualización ficha</h3>
                  <div className="d-flex align-items-center">
                    <a className="btn btn-primary btn-sm me-4 minWidthbtn" onClick={() => this.publicar()}>Publicar </a>
                      <span className="fs-xs text-muted ms-auto me-2">Cerrar</span>
                    <button className="btn-close ms-0" type="button" data-bs-dismiss="modal" />
                  </div>
                </div>
                <div className="modal-body p-0">
                  <div className="container mt-2 mt-sm-0 py-4 py-sm-5">
                    <h1 className="h2 mb-2">{this.state.descripcion}</h1>
                    <p className="mb-2 pb-1 fs-lg">{this.state.calle}</p>
                    <ul className="d-flex mb-4 list-unstyled">
                      <li className="me-3 pe-3 border-end"><b className="me-1">{this.state.nHabitaciones}</b><i className="fi-bed mt-n1 lead align-middle text-muted" /></li>
                      <li className="me-3 pe-3 border-end"><b className="me-1">{this.state.nBaños}</b><i className="fi-bath mt-n1 lead align-middle text-muted" /></li>
                      <li className="me-3 pe-3 border-end"><b className="me-1">{this.state.parking}</b><i className="fi-car mt-n1 lead align-middle text-muted" /></li>
                      <li><b>{this.state.metros} </b>sq.m</li>
                    </ul>
                    <div className="" >
                      <div className="row g-2 g-md-3" style={{minWidth: '30rem'}}>
                      <div className="col-8"> {this.state.images[0] ? <img className="rounded rounded-md-3" src={this.state.images[0]} alt="Gallery thumbnail" /> : null }  </div>
                      <div className="col-4"> {this.state.images[1] ?  <img className="rounded rounded-md-3 mb-2 mb-md-3" src={this.state.images[1]} alt="Gallery thumbnail" /> : null } 
                      {this.state.images[2] ?  <img className="rounded rounded-md-3" src={this.state.images[2]} alt="Gallery thumbnail" /> : null } </div> 
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="col-md-7 mb-md-0 mb-4"><span className="badge bg-success me-2 mb-3">Verificado</span><span className="badge bg-info me-2 mb-3">Subido por particular</span>
                        <h2 className="h3 mb-4 pb-4 border-bottom">{this.state.precio}€<span className="d-inline-block ms-1 fs-base fw-normal text-body">{this.state.operacion ==="Venta" ? null : <>/mensual</>}</span></h2>
                        {/* Overview*/}
                        <div className="mb-4 pb-md-3">
                          <h3 className="h4">Descripción</h3>
                          <p className="mb-1">{this.state.descripcionLong}</p>
                          <div className="collapse" id="seeMoreOverview">
                            {/* <p className="mb-1">Asperiores eos molestias, aspernatur assumenda vel corporis ex, magni excepturi totam exercitationem quia inventore quod amet labore impedit quae distinctio? Officiis blanditiis consequatur alias, atque, sed est incidunt accusamus repudiandae tempora repellendus obcaecati delectus ducimus inventore tempore harum numquam autem eligendi culpa.</p> */}
                          </div>
                          {/* <a className="collapse-label collapsed" href="#seeMoreOverview" data-bs-toggle="collapse" data-bs-label-collapsed="Show more" data-bs-label-expanded="Show less" role="button" aria-expanded="false" aria-controls="seeMoreOverview" /> */}
                        </div>
                         {/* Property Details*/}
                          <div className="mb-4 pb-md-3">
                            <h3 className="h4">Detalles propiedad</h3>
                            <ul className="list-unstyled mb-0">
                              <li><b>Categoría: </b>{this.state.propiedad}</li>
                              <li><b>Tipo </b>{this.state.operacion}</li>
                              <li><b>Habitaciones: </b>{this.state.nHabitaciones}</li>
                              <li><b>Baños: </b>{this.state.nBaños}</li>
                            
                            </ul>
                          </div>
                        {/* Amenities*/}
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page container*/}
          <div className="container mt-0 mb-md-4 py-0 mt-5">
            <div className="row  mt-5">
              {/* Page content*/}
              <div className="col-lg-7 mt-3">
                {/* Breadcrumb*/}
                <nav className="mb-3 mt-5" aria-label="Breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a >FotoHunters</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Añadir activo</li>
                  </ol>
                </nav>
                {/* Title*/}
                <div className="mb-4">
                  <h1 className="h2 mb-0">Sube tu activo PRO RETAIL</h1>

                  <div className="d-lg-none pt-3 mb-2">{} Información rellenada</div>
                  <div className="progress d-lg-none mb-4" style={{height: '.25rem'}}>
                    <div className="progress-bar bg-warning" role="progressbar" style={{width: '65%'}} aria-valuenow={65} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                </div>
              
           
         

                  {this.renderSteps()}
         
                {/* Action buttons */}
                <section className="d-sm-flex justify-content-between pt-2">
                {this.state.step !== 0 ?  <a  onClick={() => this.setState({step: this.state.step - 1})}className="btn btn-outline-primary btn-lg d-block ps-3 mb-3 mb-sm-2" data-bs-toggle="modal"><i className="fi-arrow-back me-2" />Atrás</a> : null}
                </section>
                
                <section className="d-sm-flex justify-content-between pt-2">
                  
                {/* <a className="btn btn-outline-primary btn-lg d-block ps-3 mb-3 mb-sm-2" href="#preview-modal" data-bs-toggle="modal"><i className="fi-eye-on me-2" />Previsualizar ficha activo</a> */}
               
                  <a className="btn btn-primary btn-lg d-block mb-2" onClick={() => this.publicar()} >Publicar activo</a>
                  </section>
              </div>
              {/* Progress of completion*/}
              {/* <aside className="col-lg-4 offset-lg-1 d-none d-lg-block">
                <div className="sticky-top pt-5">
                  <h6 className="pt-5 mt-3 mb-2">{((this.state.step + 1) * 15.66).toFixed(0)}% Completado</h6>
                  <div className="progress mb-4" style={{height:16.55}}>
                    <div className="progress-bar bg-warning" role="progressbar" style={{width: `${(this.state.step + 1) * 15.66}%` }} aria-valuenow={65} aria-valuemin={0} aria-valuemax={100} />
                  </div> 
                  <br/>
                  <div className="mb-3">Visualización activo</div>

                  <CardParticular redirect={false} eyeOnly={true} item={this.state}></CardParticular>
                </div>
              </aside> */}
            </div>
          </div>
        </main>
    
      </div>
                      
        )

    }

  }


export default Main;
