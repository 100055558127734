import React from "react";

import { auth , db } from "../firebase";

import "../App.css";


import 'firebase/storage'

import Swal from 'sweetalert2'

import municipios from "../Shared/Municipios";
import DragDrop from "../Shared/DragDrop";

import { withRouter } from "react-router";


class AddActivo extends React.Component{
  

  
  constructor(props) {
    super(props) 
    this.state = {
        step: 0,
        operacion: "Venta",
        precio: 123456,
        calle: "",
        descripcion: "",
        nBaños: 0,
        nHabitaciones: 1,
        visitas: 0,
        inmobiliaria: false,
        img: "",
        fecha: Date.now(),
        particular: true,
        visitas: 0,
        editing:false,
        imagesPlace: "https://firebasestorage.googleapis.com/v0/b/fotohunters-com.appspot.com/o/01.jpg?alt=media&token=2193b160-d6bb-4eb8-8c58-e603cbcb8fbe"
    }
  }


  componentDidMount() {
    const id = this.props.match.params.id;
    if(id !== undefined && id !== null) {
      this.editingFetch(id)
    }
  }

  editingFetch(id) {


    db.collection("activos-2023").doc(id).get().then(doc => {
      var data = doc.data()
      this.setState(data)
      this.setState({editing:true})

    })
  }

  handleChangeZonas = (event) => {
    this.setState({zona: event.target.value});
  }

  addImage(newItem) {
      this.setState({img:  newItem})
  }

  removeImage(e) {
    var arr = this.state.images
    arr = arr.filter(function(item) {
      return item !== e
   })
   this.setState({images: arr});

  }

  renderImagenes() {

        return (
          <div className="col-6 mt-2" >
          <img className="img-fluid" src={this.state.img}></img>
          </div>
        )

 
  }

  renderZonas() {
    var array = []
    var i;
    var data = municipios;
    for(i=0;i < data.length ;i++) {
      array.push(
        <option value={data[i].barrio_nombre}>{data[i].barrio_nombre}</option>
      )
    }
    return array;
  }

  async publicar() {

      if(this.state.editing === true) {
        await db.collection("activos-2023").doc(this.state.id).update(this.state);
        this.editingFetch()
      }
      else {
        let id = "id-" + Date.now();
        this.setState({id: id})
        await db.collection("activos-2023").doc(id).set(this.state);

     
      }
        Swal.fire(
          '¡Inmueble subido correctamente!',
          'Se ha añadido el inmueble a FotoHunters',
          'success'
        )

   
     
  }

  renderSteps() {
    
    if( true ) {
      return(
        <>
          {/* Basic info*/}
        <section className="card card-body border-0 shadow-sm p-4 mb-4 " id="basic-info">
                <h2 className="h4 mb-4"><i className="fi-info-circle text-primary fs-5 mt-n1 me-2" />Información basica</h2>
             
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <label className="form-label" htmlFor="ap-category">Tipo de operacion <span className="text-danger">*</span></label>
                    <select value={this.state.operacion}  onChange={(e) =>  this.setState({ operacion : e.target.value})} className="form-select" id="ap-category" required>
                      <option value="Alquiler">Alquiler</option>
                      <option value="Venta">Venta</option>

                    </select>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label className="form-label" htmlFor="ap-type">Tipo propiedad <span className="text-danger">*</span></label>
                    <select value={this.state.propiedad}  onChange={(e) =>  this.setState({ propiedad : e.target.value})}  className="form-select" id="ap-type" required>
                      <option value="Vivienda">Vivienda</option>
                      <option value="Locales">Locales</option>
                      <option value="Parking">Parking</option>
                    </select>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <label className="form-label" htmlFor="ap-type">Inmobiliaria <span className="text-danger">*</span></label>
                    <select value={this.state.inmobiliaria}  onChange={(e) =>  this.setState({ inmobiliaria : e.target.value})}  className="form-select" id="ap-type" required>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
               

                <div className="col-sm-6 mb-3">
                <label className="form-label" htmlFor="ap-address">Imagen map url / logo agencia <span className="text-danger"></span></label>
                <input value={this.state.imgmap}  onChange={(e) =>  this.setState({ imgmap : e.target.value})} className="form-control" type="text" id="ap-address" placeholder="https://logo.com/logo.png" required />
            
                  </div>
               
              </section>

              <section className="card card-body border-0 shadow-sm p-4 mb-4" id="location">
             
              <div className="row">


              <div className="col-sm-6 mb-3">
                <label className="form-label" htmlFor="ap-address">Descripcion <span className="text-danger">*</span></label>
                <input value={this.state.descripcion}  onChange={(e) =>  this.setState({ descripcion : e.target.value})} className="form-control" type="text" id="ap-address" placeholder="Inmobiliaria" required />
            
                </div>


                <div className="col-sm-6 mb-3">
                <label className="form-label" htmlFor="ap-address">Numero <span className="text-danger">*</span></label>
                <input value={this.state.numero}  onChange={(e) =>  this.setState({ numero : e.target.value})} className="form-control" type="text" id="ap-address" placeholder="Numero"  />
            
                </div>

                <div className="col-sm-6 mb-3">
                <label className="form-label" htmlFor="ap-address">Dirección <span className="text-danger">*</span></label>
                <input value={this.state.calle}  onChange={(e) =>  this.setState({ calle : e.target.value})} className="form-control" type="text" id="ap-address" placeholder="Avenida, Calle, Piso..." required />
            
                </div>
                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="ap-zip">Código postal<span className="text-danger">*</span></label>
                  <input value={this.state.codigo}  onChange={(e) =>  this.setState({ codigo : e.target.value})}  className="form-control" type="number" id="ap-zip" placeholder="Enter Zip code"  required />
                </div>

                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="ap-zip">Longitud<span className="text-danger">*</span></label>
                  <input value={this.state.lng}  onChange={(e) =>  this.setState({ lng : e.target.value})}  className="form-control" type="number" id="ap-zip" placeholder="Longitud"  required />
                </div>


                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="ap-zip">Latitud<span className="text-danger">*</span></label>
                  <input value={this.state.lat}  onChange={(e) =>  this.setState({ lat : e.target.value})}  className="form-control" type="number" id="ap-zip" placeholder="Latitud"  required />
                </div>
              </div>
              
          
            </section>


            {/* Photos / video*/}
         <section className="card card-body border-0 shadow-sm p-4 mb-4" id="photos">
            <h2 className="h4 mb-4"><i className="fi-image text-primary fs-5 mt-n1 me-2" />Fotos / Videos</h2>
        
            <div className="row">
            <div className="col-12 col-md-6">
              <DragDrop onDone={(newItem) => this.addImage(newItem)}></DragDrop>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                {this.renderImagenes()}


                </div>
              </div>
            </div>
          </section>

    
        </> 
      )
    }
   
  }


   render() {
        return(
        <div>
        <main className="page-wrapper mt-5">
          {/* Preview modal*/}
      
          {/* Page container*/}
          <div className="container mt-0 mb-md-4 py-0 mt-5">
            <div className="row  mt-5">
              {/* Page content*/}
              <div className="col-lg-7 mt-3">
                {/* Breadcrumb*/}
                <nav className="mb-3 mt-5" aria-label="Breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a>FotoHunters</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Añadir propiedad</li>
                  </ol>
                </nav>
                {/* Title*/}
                <div className="mb-4">
                  <h1 className="h2 mb-0">Añade tu propiedad </h1>
                  <span className="text-small">Ya seas particular o agencia <b>Fotohunters</b> te permite publicar tus inmuebles de forma gratuita </span>

                  <div className="d-lg-none pt-3 mb-2">{} Información rellenada</div>
                  <div className="progress d-lg-none mb-4" style={{height: '.25rem'}}>
                    <div className="progress-bar bg-warning" role="progressbar" style={{width: '65%'}} aria-valuenow={65} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                </div>
              
           
         

                  {this.renderSteps()}
         
                {/* Action buttons */}
                <section className="d-sm-flex justify-content-between pt-2">
                {this.state.step !== 0 ?  <a  onClick={() => this.setState({step: this.state.step - 1})}className="btn btn-outline-primary btn-lg d-block ps-3 mb-3 mb-sm-2" data-bs-toggle="modal"><i className="fi-arrow-back me-2" />Atrás</a> : null}
                </section>
            
              </div>
              {/* Progress of completion*/}
            
            </div>
            <a className="btn btn-primary btn-lg d-block mb-2" onClick={() => this.publicar()} >Publicar activo</a>
          </div>
        </main>
    
      </div>
                      
        )

    }

  }


export default  withRouter(AddActivo);
