import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import "../App.css";


import {
  auth,
  registerWithEmailAndPassword,
} from "../firebase";
import Footer from "../Shared/Footer";



function getQueryVariable(variable)
{
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    // alert(pair[1])//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
}

function Register() {


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cp, setCp] = useState();

  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();


  const register = () => {
    const id = getQueryVariable('id')
    if(id) {
    registerWithEmailAndPassword(email, password,id,cp);
    } else {
      registerWithEmailAndPassword(email, password, null,cp);

    }
    // if (!name) alert("Please enter name");

  };

  useEffect(() => {
    if (loading) return;
    if (user) history.replace("/cuenta");
  }, [user, loading]);


  return(
    <main id="content" className=" mx-auto mt-5 backrester" role="main">
     
    <br/>
    <br/>
    <div class="">
    <div class="BigAlert width-100 ">
        <a className="text-center link text-white">Necesitas registrate para poder acceder al <b>HuntersMaps</b></a>
      </div>
      <div class="container mt-5 ">
        <div class="mx-auto width-40" >

          <div class="card card-lg zi-2 ">
            <br/>
            <div class="container text-left">
              <h4 class="card-title text-dark">Registro</h4>
            </div>
            <br/>




            <div class="card-body">
              <div class="js-validate need-validate">
            

                <div class="mb-4">
                  <label class="form-label" for="loginFormEmail">Email</label>
                  <input 
                    type="email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    class="form-control form-control-lg" 
                    name="loginFormEmailName" 
                    id="loginFormEmail" 
                    placeholder="email@site.com" 
                    aria-label="email@site.com" 
                    required 
                  />
                  <span class="invalid-feedback">Porfavor introduce una dirección correcta</span>
                </div>



                <div class="mb-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="form-label" for="loginFormPassword">Contraseña</label>
                    
                  </div>

                  <div class="">
                    <input 
                      type="password" 
                      class="js-toggle-password form-control form-control-lg" 
                      name="loginFormPasswordName" 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="loginFormPassword" 
                      placeholder="Contraseña" 
                      aria-label="Password" 
                      required
                   
                    />
              

                  </div>
                  <span class="invalid-feedback">Contraseña invalidad, porfavor introduce una contraseña correcta.</span>
                </div>

                <div class="mb-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="form-label" for="loginFormPassword">Código postal</label>
                    
                  </div>

                  <div class="">
                    <input 
                      type="number" 
                      class="js-toggle-password form-control form-control-lg" 
                      name="cp" 
                      value={cp}
                      onChange={(e) => setCp(e.target.value)}
                      id="numberCp" 
                      placeholder="Código postal" 
                      aria-label="Código postal" 
                      required
                   
                    />
              

                  </div>

                </div>

                <div class="d-grid mb-4">
                  <button  class="btn btn-secondary btn-lg"  onClick={() => register(email, password)}>Registro</button>
                  <br/>
                  <a class="" href="https://docs.google.com/document/d/1GOU-0iWAxLbhdhBgap7FuaND-iVVOI7S/edit?usp=sharing&ouid=115900493347390928615&rtpof=true&sd=true">Registrandoté <span className="navlink bold link"  >aceptas los términos y condiciones</span> </a>
                </div>

              

              <p class="card-text">¿Tienes una cuenta? <a class="link text-dark" href="./page-signup.html"><Link to="/login" className="text-dark">Inicia sesión</Link></a></p>

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>


    <br/>
    <br/>
    <br/>
    <Footer></Footer>

  </main>
  )
}


export default Register;
