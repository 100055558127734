import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, signInWithEmailAndPassword, signInWithGoogle } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import "../App.css";
import Footer from "../Shared/Footer";


function Login2() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();


  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history.replace("/cuenta");
  }, [user, loading]);


  return(
    <main id="content" className=" mx-auto  mt-5" role="main">
    <br/>
    <br/>
    <div class="">
      <div class="container content-space-1 content-space-t-md-1  mt-2 ">
        <div class="mx-auto width-40" >

          <div class="card card-lg zi-2 ">
          <br/>

            <div class="container text-left">
              <h4 class="card-title text-dark">Inicio de sesión</h4>
            </div>
            <br/>


            <div class="card-body">
              <div class="js-validate need-validate" >
          

                <div class="mb-4">
                  <label class="form-label" for="loginFormEmail">Email</label>
                  <input 
                    type="email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    class="form-control form-control-lg" 
                    name="loginFormEmailName" 
                    id="loginFormEmail" 
                    placeholder="email@site.com" 
                    aria-label="email@site.com" 
                    required 
                  />
                  <span class="invalid-feedback">Porfavor introduce una dirección correcta</span>
                </div>



                <div class="mb-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="form-label" for="loginFormPassword">Contraseña</label>
                    
                  </div>

                  <div class="">
                    <input 
                      type="password" 
                      class="form-control form-control-lg" 
                      name="loginFormPasswordName" 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="loginFormPassword" 
                      placeholder="Contraseña" 
                      aria-label="Password" 
                      required
                      data-hs-toggle-password-options='{
                        "target": "#loginFormChangePassTarget",
                        "defaultClass": "bi-eye-slash",
                        "showClass": "bi-eye",
                        "classChangeTarget": "#changePassIcon"
                      }' 
                    />
                    <Link to="/reset"><a class="form-label-link text-dark" href="./page-reset-password.html">¿Se te ha olvidado la contraseña?</a></Link>

                   
                  </div>
                  
                  <span class="invalid-feedback">Contraseña invalidad, porfavor introduce una contraseña correcta.</span>
                </div>

                <div class="d-grid mb-4">
                  <button type="submit" class="btn btn-secondary btn-lg"  onClick={() => signInWithEmailAndPassword(email, password)}>Inicia sesión</button>
                </div>

              <p class="card-text">¿No tienes una cuenta? <a class="link text-dark" href="./page-signup.html"><Link to="/register" className="text-dark">Regístrate</Link></a></p>

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <br/>
    <br/>
    <br/>
    <Footer></Footer>

   

  </main>
  )
}

export default Login2;
