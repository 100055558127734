import React from "react";

import {  auth, db, logout } from "../firebase";

import Footer from "../Shared/Footer";
import CardParticular from "../Shared/CardParticular";

import notFound from "../assets/banner.png"



import Comprar from "../Shared/Comprar";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

class Cuenta extends React.Component {


  constructor(props) {
    super(props) 
    this.state = {
        premium: false, 
        pro:false,
        tab:0,
        activos: [],
        loading:true,
    }
  }

  componentDidMount() {
    this.getUser()
    setTimeout(() => {
        this.getUser()
    }, 1000);
  }

  renderActivos() {

    if(this.state.activos.length > 0 && this.state.loading !== true) {
      return(this.state.activos.map(item => this.renderActivoLista(item))  )
    } else {
      return (
        <>
        <div className="container mt-5 text-center align-items-center ">
  
          <img src={notFound} className="img-fluid" width={"200px"}></img>
          <h4>No tienes ningún activo publicado</h4>
  
        </div>
        </>
      )
    }
  }
  
  renderActivoLista(item)  {

    return(
      <div className="col-6">
      <CardParticular  item={item} edit={true} func={() => this.delete(item.id)}/>
      </div>
  )
  
  
  }


  delete(id) {
    Swal.fire({
      title: '¿Estás seguro de borrar el activo?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteDB(id)
       
      } 
    })
  }

  deleteDB(id) {
    db.collection("activos-2023").doc(id).delete();
    db.collection("users").doc(auth.currentUser.email).collection("activos").doc(id).delete()
    Swal.fire('Activo borrado', '', 'success')
    this.setState({activos:[]})
    this.getInmuebles()
  }
  

  getUser = async () => { 

      if(auth.currentUser) {
        db.collection('users').doc(auth.currentUser.email).get()
        .then(doc => {
            const data = doc.data();
            this.setState({activos:[]})
            this.getInmuebles()
          
    
            this.setState({user:data,})
            if(data.premium === true) {
                this.setState({premium: true})

            }
            if(data.pro === true) {
              this.setState({premium: true, pro: true})
          }

          if(this.state.activos.length > 0 ) {
            this.setState({activos:[],loading:false})
            this.getInmuebles()
           }
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
  
      } else {
        window.location.href = "/register";
      }

   
    
 
}

  

addActivo(newItem) {
    this.setState({ activos: [...this.state.activos, newItem] })
  }


  async getInmuebles() {
      this.setState({inmuebles:[]});
      await db.collection("users").doc(auth.currentUser.email).collection('activos').get()
      .then(res => {
          res.forEach(doc => {

              let newItem = doc.data();
              this.addActivo(newItem)

          });
          this.setState({loading:false})
      })
      .catch(err => { console.error(err) });
  } 

  render() {
    return (
      <main>
      <div>
        {/* Page content*/}
        <div className="container pt-5 pb-lg-4 mt-5 mb-sm-2">
          {/* Breadcrumb*/}
          <nav className="mb-4 pt-md-3" aria-label="Breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Fotohunters</a></li>
              <li className="breadcrumb-item"><a >Tu cuenta</a></li>

            </ol>
          </nav>
          {/* Page content*/}
          <div className="row">
            {/* Sidebar*/}
            <aside className="col-lg-4 col-md-5 pe-xl-4 mb-5">
              {/* Account nav*/}
              <div className="card card-body border-0 shadow-sm pb-1 me-lg-1 mt-3">
                <div className="d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-4 mt-2"><img className="rounded-circle" src="img/avatars/13.png" width={48} />
                  <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-2">
                    <h2 className="fs-lg mb-0 ">Tu cuenta</h2>
                    <ul className="list-unstyled fs-sm mt-3 mb-0">
                      <li><a className="nav-link fw-normal p-0"><i className="fi-calendar opacity-60 me-2" />Usuario desde {auth.currentUser?.metadata?.creationTime}</a></li>
                      <li><a className="nav-link fw-normal p-0"><i className="fi-mail opacity-60 me-2" />{auth.currentUser?.email}</a></li>
                    </ul>
                  </div>
                </div>
                <Link className="btn btn-primary  btn-lg w-100 mb-3 text-dark"  to="/add"><i className="fi-plus me-2" />Añade tu activo </Link><a className="btn btn-outline-secondary d-block d-md-none w-100 mb-3" href="#account-nav" data-bs-toggle="collapse"><i className="fi-align-justify me-2" />Menu</a>
                <div className="collapse d-md-block mt-3" id="account-nav">
                  <div className="card-nav">
                   
                   
                  <a className={this.state.tab === 0 ? "card-nav-link cursor active" : "card-nav-link cursor"} onClick={() => this.setState({tab:0})}>
                    <i className="fi-user opacity-60 me-2" />Personal Info</a>
                    <a className={this.state.tab === 1 ? "card-nav-link cursor active" : "card-nav-link cursor"} onClick={() => this.setState({tab:1})}>
                      <i className="fi-home opacity-60 me-2" />Tus activos</a>
                    <a to="/contacto" className="card-nav-link" href="mailto:info@fotohunters.com" ><i className="fi-help opacity-60 me-2" />Ayuda</a>
                    <a className="card-nav-link cursor" onClick={() => logout()} ><i className="fi-logout opacity-60 me-2" />Cerrar sesión</a></div>
                </div>
              </div>
            </aside>
            {/* Content*/}
            <div className="col-lg-8 col-md-7 mb-5">
              <h1 className="h2">Administra tu cuenta</h1>
                <>

                {this.state.tab === 0 ? 
                <>
                <p className="pt-1">Cambia tu subscripción a Fotohunters</p>
                <p>Suscríbite para acceder a todas las funcionalidad de Fotohunters</p>




                  <a     style={{textDecoration: 'none'}} class="list d-flex justify-content-between align-items-center  shadow  p-2 border-3 rounded mt-3 bg-dark text-white ">

                  <div class="card-body ">
                      <div className="row mt-3">
                          <div className="col-8">
                              <h4 class="card-text text-white">Plan PRO</h4>
                              <p>Con el plan PRO de fotohunters puedes acceder a todas las funcionalidades de Fotohunters, herramientas para el sector terciario, acceso a Locales y al <b>HuntersMap PRO</b></p>
                              <small>49.99€/mes</small>
                          </div>
                          

                          <div className="col-2  d-flex justify-content-between align-items-center">

                        
                          {this.state.user?.pro === true ? 
                                <div>
                                <a className="btn btn-secondary"><b>Plan activo</b><i className="fi-check-circle text-success pl-2"></i> </a>
                                <br/>   
                                <a href="https://zvtwc2ccu3i.typeform.com/to/fiBkhoVm" target={"_blank"}> <span className="small text-center  text-decoration-none">Cancelar suscripción</span> </a>
                                </div>
                                : 
                                <>
                                <Comprar/>
                                </>
                            } 
                          
                          </div>
                      </div>
                    

                  </div>
                  </a>
                  </>
                : 
                <>
                <div className="row">
                {this.renderActivos()}
                
                <div class="card-body card mt-5">
                      <div className="row mt-3">
                          <div className="col-8">
                              <h4 class="card-text text-dark">¿Eres una agencia?</h4>
                              <p>Publica sin coste alguno todos tus activos sin necesidad de subirlos tu mismo, conectando tu actual base de datos con Fotohunters</p>

                          </div>
                          

                          <div className="col-2  d-flex justify-content-between align-items-center">
                                <a href="mailto:info@fotohunters.com" className="btn btn-secondary"><b>Contacta con nosotros</b><i className="fi-mail-circle text-success pl-2"></i> </a>

                          </div>
                      </div>
                    

                  </div>
                </div>

                </> }
                </>


            </div>
            
          </div>
        </div>
      </div>

      <Footer/>
      </main>
    )
  }

 }


  export default Cuenta;