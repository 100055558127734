
import React from "react";
import { Link } from "react-router-dom";


const CardParticular = ({item, eyeOnly, redirect, func, edit}) => {
    return (
        <div className="col  pb-1 cardFixedParti">
        { redirect !== false && edit !== true ? 
        <Link to={`/activo/${item.id}`}  style={{ textDecoration: 'none' }}>    
        <div class="card bg-size-cover bg-position-center border-0 overflow-hidden cardFixedParti h-100" style={item.images ? {backgroundImage: `url(${item.images[0]})`} : null}>
          <span class="img-gradient-overlay"></span>
          <div class="card-body content-overlay pb-0">
            <span class="badge bg-info fs-sm">{item.zona}</span>
          </div>
          <div class="card-footer content-overlay border-0 pt-0 pb-4">
            <div class="d-sm-flex justify-content-between align-items-end pt-3 mt-2 mt-sm-5">
              <a href="#" class="text-decoration-none text-light pe-2">
                <div class=" text-bold text-uppercase pt-2 mb-1 bold">{item.operacion}</div>
                <h3 class="h5 text-light mb-1 text-decoration-none">{item.descripcion}</h3>
                <div class="fs-sm opacity-70">
                  <i class="fi-map-pin me-1"></i>
                  {item.calle}
                </div>
              </a>
              { edit === true ?
                <>
              <button onClick={func} type="button" class="btn btn-danger btn-icon border-end-0 border-top-0 border-bottom-0 border-light fs-sm">
                  <i class="fi-trash"></i>
                </button>
                  </>
                : null}

           
            </div>
          </div>
        </div>
        </Link>
        : 
        <div class="card bg-size-cover bg-position-center border-0 overflow-hidden cardFixed h-100" style={item.images ? {backgroundImage: `url(${item.images[0]})`} : null}>
          <span class="img-gradient-overlay"></span>
          <div class="card-body content-overlay pb-0">
            <span class="badge bg-info fs-sm">{item.zona}</span>
          </div>
          <div class="card-footer content-overlay border-0 pt-0 pb-4">
            <div class="d-sm-flex justify-content-between align-items-end pt-3 mt-2 mt-sm-5">
              <a href="#" class="text-decoration-none text-light pe-2">
                <div class=" text-bold text-uppercase pt-2 mb-1 bold">{item.operacion}</div>
                <h3 class="h5 text-light mb-1 text-decoration-none">{item.descripcion}</h3>
                <div class="fs-sm opacity-70">
                  <i class="fi-map-pin me-1"></i>
                  {item.calle}
                </div>
              </a>

              <div class="btn-group ms-n2 ms-sm-0 mt-3">
               {eyeOnly !== true && edit !== true?  <a href="#" class="btn btn-primary px-3">{item.precio}€</a> : null}

               { edit === true ?
                <>
              <button onClick={func} type="button" class="btn btn-danger btn-icon border-end-0 border-top-0 border-bottom-0 border-light fs-sm">
                  <i class="fi-trash"></i>
                </button>
                  </>
                : null}

              </div>
           
            </div>
          </div>
        </div>

        
        
        }
        </div>

        
    )

}

export default CardParticular;