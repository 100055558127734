import React, { useCallback, useState } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'

import styled, { css } from "styled-components";

import { uploadFromBlobAsync } from './storage'

const StyledLabel = styled.label`
  display: flex;
  width: 300px;
  height: 200px;
  border: #ccc 2px dashed;
  margin: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ dragEnter }) =>
    dragEnter &&
    css`
      border-color: #666;
    `}
`;

function DragDrop({onDone}) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles?.[0]

    if (!file) {
      return
    }

    setIsLoading(true)
    setError(null)
    setMessage(null)

    try {
      const url = await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `${file.name}_${Date.now()}`,
      })
        onDone(url)

        } catch (e) {
      setIsLoading(false)
      setError(e.message)
      return
    }

    setIsLoading(false)
    setMessage('File was uploaded 👍')
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
        <div className="bropbox">
        <StyledLabel
            // dragEnter={dragEnter}
            htmlFor="images-upload"
            // onDragEnter={handleDragEnter}
        >
      <input
          className='input' {...getInputProps()}
        />
        Arrastra imagenes o selecciona
        </StyledLabel>
      
        </div>
         {/* <Dropzone  onDrop={onDrop}></Dropzone> */}
        {isLoading ? (
            <p>Subiendo imagen...</p>
        ) : isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>Arrastra las imágenes que quieras subir o pincha para seleccionar</p>
        )}


    </div>
  )
}

export default DragDrop