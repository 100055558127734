
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";

import {   auth } from "../firebase";


export default function Comprar() {
    let history = useHistory();
    const [user] = useAuthState(auth);


    const goComprar = () => {
        if(user) {
            window.location.href = "https://buy.stripe.com/dR68yJdT49gdfv2288"
        } else {
          history.push(`/register`);
        }
      }
    
    

    return (<a className="btn btn-primary mt-2 overThumb cursor" onClick={() => goComprar()}>Suscríbete</a>)
  }