
import React, { useState } from "react";
import { Link } from "react-router-dom";


import venta from '../assets/vende.png'; // Tell
// import alquila from '../assets/alquila.png'; // Tell


import ImageViewer from "react-simple-image-viewer";

const Card = ({item}) => {

    const [openImg, setOpenImg] = useState(false);
    const [view, setView] = useState([]);


    const openImageViewer = (() => {
      setOpenImg(true)
      setView([item.img])
    });
  
     const closeImageViewer = () => {
      setOpenImg(false)
      setView([])
  
    };
  

    return (
        <div className="col  pb-1">
        <div className="card cardFixed shadow-sm card-hover border-0 h-100 ">
          <div className="card-img-top card-img-hover"><a className="img-overlay" href="real-estate-single.html" />

            <div className="content-overlay end-0 top-0 pt-3 pe-3">
              <button className="btn btn-icon btn-light btn-xs text-primary rounded-circle" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to Wishlist"><i className="fi-heart" /></button>
            </div>
          </div>
        
          <div class="card-body content-overlay ml-0 pr-1">
            <span class="badge bg-info fs-sm ">{item.cp}</span>
          </div>
          <div className="card-body position-relative pb-3">


            { item.calle ? <Link to={`/activo/${item.id}`}> <h3 className="h4 mb-2"><a className="nav-link stretched-link">{item.calle.slice(0,40)}...</a></h3>  </Link> : null}
            <p className="mb-2 fs-sm text-muted">{item.operacion}</p>
            <div className="row">
              <div className="col-8 text-dark"> 
            { item.fecha ? <p className="text-dark">Añadida: {item.fecha}</p>: null }
              
              </div>
              <div className="col-4 text-dark">
              {item.operacion === "Venta" ? 
                <p>
                  <img width={120} className="img-fluid  border text-right float-right " src={venta}/> 
                </p>
                : 
                <p>
                {/* <img width={120} className="img-fluid  border text-right float-right " src={alquila}/>  */}
                </p>  
                
              }

              </div>
              <br/>
              <br/>
            </div>
              <br/>

            <div className=" d-flex align-items-center justify-content-center  pt-2 text-nowrap text-dark">
            <span className="d-inline-block mx-1 text-dark  fs-sm">{item.visitas}<i className="fi-eye-on ms-1 mt-n1 fs-lg text-muted" /></span>


            {openImg  && (
          <ImageViewer
            src={view}
            currentIndex={0}
            onClose={() => closeImageViewer()}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)"
            }}
            closeOnClickOutside={true}
          />
        )}
          </div>
  

         
          </div>
  
          
        </div>
      <br></br>
      <br></br>

      </div>
    )

}

export default Card;