import React, { useEffect , useState} from "react";

import {
  Link
} from "react-router-dom";


import "./Navbar.css"
import "../App.css";

import { useAuthState } from "react-firebase-hooks/auth";


import { logout ,auth} from "../firebase";


import {  Navbar, Nav, Container} from 'react-bootstrap';

import logo from "../assets/logo-text.png"

function NavbarComponent() {
  const [user, loading] = useAuthState(auth);
  const [LogButton, setLogButton] = useState(false);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (user)
     { setLogButton(false) } else {
       setLogButton(true)
     }
  }, [user, loading]);

    function logoutSesion() {
      logout()
      setLogButton(true)
    }


    return (
      <>
      <Navbar  expanded={expanded} light bg="light" className="navbar navbar-expand-lg transparent shadow-sm" fixed="top" expand="lg" >
      <Container>
        <Link to="/"><a className="navbar-brand  text-center text-decoration-none" ><img className="text-dark text-decoration-none" src={logo}  width="186" alt="FotoHunters" /> </a> </Link>
        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}  aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end" style={{ width: "100%" }}>
         
            <Nav.Link onClick={() => setExpanded(false)} class="nav-item dropdown">
             <Link to="/nosotros"  style={{"text-decoration": "none"}} >
              <li class="nav-item dropdown"><a class="nav-link text-dark ">Nosotros</a>
              </li>
              </Link> 
              </Nav.Link>

              <Nav.Link onClick={() => setExpanded(false)} class="nav-item dropdown">
             <Link to="/hunters"  style={{"text-decoration": "none"}} >
              <li class="nav-item dropdown"><a class="nav-link text-dark ">Hazte Hunter</a>
              </li>
              </Link> 
              </Nav.Link>


              <Nav.Link onClick={() => setExpanded(false)} class="nav-item dropdown">
            <Link to="/huntermaps"  style={{"text-decoration": "none"}} >
              <li class="nav-item dropdown"><a class="nav-link text-dark ">HuntersMaps    <i className="fi-map me-2 text-center"></i></a>
              </li>
              </Link>
              </Nav.Link>



              <Nav.Link onClick={() => setExpanded(false)} class="nav-item dropdown">
            
              </Nav.Link>

              
              <Nav>


          <Nav.Link   onClick={() => setExpanded(expanded ? false : "expanded")}  class="nav-item dropdown">
          {LogButton ?
          <>        
            <Link to="/register"  class="nav-item " className="btn btn-primary btn-sm ms-2 order-lg-3">
            <i className="fi-user me-2"></i><span>Accede a la plataforma</span><span class='d-sm-inline'></span>
            </Link>
          </>
          :
          <>        

          
          <Link  onClick={() => setExpanded(expanded ? false : "expanded")}  to="/cuenta" className="btn btn-primary ms-2  btn-sm   mr-0" href="/dashboard">
            <i className="fi-user me-2 text-center"></i><span >Cuenta</span><span class='d-sm-inline'></span>
          </Link> 
          </>
          
          }            
          </Nav.Link>


    
          </Nav>

          </Nav>
        </Navbar.Collapse>

       
      </Container>
    </Navbar>

    </>
    
    )
  

}

export default NavbarComponent;
